import Condition from "@/assets/js/notifications/Condition.class";
import Hook from "@/assets/js/notifications/Hook.class";
import Pair from "@/assets/js/notifications/Pair.class";
import get from 'lodash/get'
import axios from "axios";
import union from "lodash/union";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import {forIn, values} from "lodash";
import {sortBy} from "lodash/collection";
import validateCondition from "@/assets/js/notifications/validateCondition";
import store from '@/store';
import randomColor from "randomcolor";

export default class Notification {
  constructor(init = {}) {
    this.sync_watchlist = init?.sync_watchlist ? true : false
    this.marketplace_signal_id = init?.marketplace_signal_id || null
    this.editMode = init?.editMode || false
    this.id = init?.id || null
    this.pair = this.setPair(init?.pair || init?.pairs)/* init?.pair ? new Pair(init.pair) : null */
    this.sendTypes = Array.isArray(init?.send_types) ? init.send_types : []
    this.comment = init?.comment || null
    this.repeatableObj = get(init, 'repeatable') ? Notification.repeatableList.every : Notification.repeatableList.once
    this.repeatableStr = this.repeatableObj.label
    this.active = init?.active || null
    this.counter = init?.counter || null
    this.metAt = init?.met_at ? moment(init.met_at).utc(true).format('DD.MM.YYYY HH:mm') : '-'
    this.createdAt = init?.created_at ? moment(init.created_at).utc(true).format('DD.MM.YYYY HH:mm') : '-'
    this.end_published_date = init?.end_published_date ? moment(init.end_published_date).utc(true).format('DD.MM.YYYY HH:mm') : null
    this.subscribe_finished_at = init?.subscribe_finished_at ? moment(init.subscribe_finished_at).utc(true).format('DD.MM.YYYY HH:mm') : null
    this.conditions = this.setConditions(init?.conditions)
    this.hooks = this.setHooks(init?.hooks)
    this.draft_id = init?.draft_id || null
    this.is_published = init?.is_published
    this.can_subscribe = init?.can_subscribe
    this.is_futures = !!init?.is_futures
    this.is_futures_init = this.is_futures

    // this.watchList = get(init, 'watchList', null)
    this.coins_count = get(init, 'coins_count', 0)
    this.base_coins_count = get(init, 'base_coins_count', 0)
    this.coins_pairs = this.setPairs(init?.coins_pairs || init?.pairs, 1, null)
    this.base_coins_pairs = this.setPairs(init?.base_coins_pairs || init?.coins_pairs || init?.pairs, 0, [])
    this.subscribe_duration = this.setSubscribeDuration(init.subscribe_duration)/* init.activity || init.subscribe_duration || Notification.durationList['1m'] */
    this.is_public = get(init, 'is_public', false)
    this.is_single = get(init, 'is_single', true)
    this.comment = get(init, 'comment', '')
    this.name = get(init, 'name', '')
    this.description = get(init, 'description', '')
    this.creator = get(init, 'creator', {})
    this.subscribers_count = get(init, 'subscribers_count', '')
    this.published_date = init?.published_date ? moment(init.published_date).utc(true).format('DD.MM.YYYY HH:mm') : null
    this.is_subscribed = get(init, 'is_subscribed', false)
    this.is_subscribed_active = get(init, 'is_subscribed_active', false)
    this.isPaused = !init?.push_status
    this.byUser = init?.creator?.id === store.getters['user/userData']?.id
    this.enable = get(init, 'enable', false)
    this.tv_notify_enable = get(init, 'tv_notify_enable', false)
    this.tv_notify_color = get(init, 'tv_notify_color', randomColor())
    this.tv_notify_icon = get(init, 'tv_notify_icon', 0xf0a2)
    if(this.tv_notify_icon === 0xf132){
      this.tv_notify_icon = 0xf071
    }
    this.tv_notify_size = get(init, 'tv_notify_size', 19)
    this.tv_notify_days = Object.values(Notification.plotOnChartDurationList).find(item => init.tv_notify_days == item.value) || Notification.plotOnChartDurationList['1m']
    this.can_play_pause = this.is_subscribed_active || (!this.is_subscribed && this.byUser)
  }

  setSubscribeDuration(data){
    if(!data) return Notification.durationList['1m']
    if(typeof data === 'object') return data
    if(data <= 30) return Notification.durationList['1m']
    if(data <= 60) return Notification.durationList['2m']
    return Notification.durationList['3m']
  }

  setPair(data){
    if(!data) return null
    if(Array.isArray(data) && data.length === 1) return new Pair(data[0])
    return new Pair(data)
  }

  setPairs(data, minLength = 1, def = null){
    if(Array.isArray(data) && data.length > minLength) return data.map(item => new Pair(item))
    return def
  }

  setConditions(arr) {
    if (!arr || !Array.isArray(arr)) return []
    return arr.map(v => new Condition(v))
  }

  setHooks(arr) {
    if (!arr || !Array.isArray(arr)) return []
    return arr.map(h => new Hook(h))
  }

  get pairSymbol() {
    return this.pair.symbol
  }

  get pairExchangeSymbol() {
    if (!this.pair) return ''
    return this.pair.exchange + ':' + this.pair.symbol
  }

  get conditionsStr() {
    return this.conditions.map(v => {
      const param = v.param
      if (param === 'price') return param
      else return v.tf + ' ' + param
    })
  }

  get setsForPair() {
    const pairId = this.pair?.id
    const pairExchange = this.pair?.exchange
    if (!pairId || !pairExchange) return []
    return Notification.sets.filter(set => {
      return !(
        (set.pairs && Array.isArray(set.pairs) && set.pairs.length && !set.pairs.includes(pairId))
        || (set.exchanges && Array.isArray(set.exchanges) && set.exchanges.length && !set.exchanges.includes(pairExchange))
        || (set.excludePairs && Array.isArray(set.excludePairs) && set.excludePairs.length && set.excludePairs.includes(pairId))
      )
    })
  }

  get tfComputed() {
    if (this.coins_pairs) return Notification.sets.find(item => item.key === "watchlist")?.tf
    if (!this.setsForPair.length) return []
    return this.setsForPair.reduce((acc, set) => union(acc, set?.tf), [])
  }

  paramCalculate(tf) {
    if (this.coins_pairs) {
      const groupConfig = Notification.sets.find(item => item.key === "watchlist")
      const vars = groupConfig?.vars.reduce((acc, variable) => { // редьюс по финальному массиву нужных переменных
        forIn(Notification.vars, (section, sectionKey) => { // итерация по справочнику переменных
          forIn(section.items, (value, key) => { // итерация по каждой группе
            if (variable === key) { // когда нашли нашу переменную в справочнике
              if (!acc[sectionKey]) { // если в результирующем массиве нет еще такой группы, то добавляем
                acc[sectionKey] = {
                  label: section.group_label,
                  order: section.order,
                  key: sectionKey,
                  items: [{
                    key: key,
                    value: value,
                  }]
                }
              } else {
                acc[sectionKey]['items'].push({ // наполняем в существующую группу
                  key: key,
                  value: value,
                })
              }
            }
          })
        })
        return acc
      }, {})
      return values(sortBy(vars, (value) => value.order))
    }else{
      if (!tf) return []
      const vars = this.setsForPair
        .filter(v => v.tf.includes(tf))
        .reduce((acc, set) => union(acc, set.vars), [])
        .reduce((acc, variable) => { // редьюс по финальному массиву нужных переменных
          forIn(Notification.vars, (section, sectionKey) => { // итерация по справочнику переменных
            forIn(section.items, (value, key) => { // итерация по каждой группе
              if (variable === key) { // когда нашли нашу переменную в справочнике
                if (!acc[sectionKey]) { // если в результирующем массиве нет еще такой группы, то добавляем
                  acc[sectionKey] = {
                    label: section.group_label,
                    order: section.order,
                    key: sectionKey,
                    items: [{
                      key: key,
                      value: value,
                    }]
                  }
                } else {
                  acc[sectionKey]['items'].push({ // наполняем в существующую группу
                    key: key,
                    value: value,
                  })
                }
              }
            })
          })
          return acc
        }, {})
      return values(sortBy(vars, (value) => value.order))
    }

  }

  dataToSave() {
    const obj = {
      is_futures: !!this.is_futures
    }
    if(this.pair)obj.pairs = [this.pair]
    if(this.coins_pairs)obj.pairs = this.coins_pairs
    // if(this.watchList?.mainId)obj.watchlist_id = this.watchList?.mainId
    obj.send_types = this.sendTypes
    if(!this.coins_pairs)obj.repeatable = this.repeatableObj.id === 'every'
    const filteredConditions = this.conditions.map(v => {
      if(validateCondition(v, false)) return v.toSave()
    })
    obj.hooks = this.hooks || []
    obj.conditions = filteredConditions.every(item => item) ? filteredConditions : null
    if(this.name)obj.name = this.name
    if(this.description)obj.description = this.description
    if(this.coins_pairs)obj.is_public = this.is_public
    if(this.coins_pairs){
      if(this.sync_watchlist){
        obj.sync_watchlist = 'binance_futures'
      }else{
        obj.sync_watchlist = 'none'
      }
    }
    if(this.coins_pairs && this.subscribe_duration?.value)obj.subscribe_duration = this.subscribe_duration.value
    if(this.comment)obj.comment = this.comment
    if(this.draft_id)obj.draft_id = this.draft_id
    obj.tv_notify_enable = this.tv_notify_enable
    if(this.tv_notify_color)obj.tv_notify_color = this.tv_notify_color
    if(this.tv_notify_icon)obj.tv_notify_icon = this.tv_notify_icon
    if(this.tv_notify_size)obj.tv_notify_size = this.tv_notify_size
    if(this.tv_notify_days)obj.tv_notify_days = this.tv_notify_days.value || 0

    obj.is_futures = this.marketplace_signal_id || this.id ? this.is_futures_init : this.is_futures

    return obj
  }

  save() {
    return axios.post('api/v1/signals/marketplace', this.dataToSave())
  }

  update(signalId) {
    // const dataToSave = this.dataToSave()
    // const data = {
    //   comment: dataToSave.comment || ''
    // }
    // if(this.is_single){
    //   data.conditions = dataToSave.conditions
    // }
    return axios.post('api/v1/signals/marketplace/' + signalId, this.dataToSave())
  }

  saveAsDraft() {
    return axios.post('api/v2/marketplace-draft', this.dataToSave())
  }

  delete() {
    return axios.delete('api/v1/signals', {
      data: {
        id: this.id
      }
    })
  }

  copy() {
    return new Notification(cloneDeep(this))
  }

  resetConditions() {
    this.conditions = [new Condition()]
  }

  checkParam(index) {
    if (!index && index !== 0) return
    const condition = this.conditions[index]
    const param = condition?.param
    if (!param) return
    const arr = this.paramCalculate(condition.tf)
    if (!arr.some(item => item.items.some(subItem => subItem.key === param))) condition.param = null
  }

  conditionName(param) {
    let name = Notification.vars_flat[param] || param
    if (this.pair?.quote && this.pair?.quote !== 'USDT') {
      name = name.replace('USDT', this.pair.quote)
    }
    return name
  }

  static sendTypes = []
  static sets = []
  static vars = []
  static vars_flat = []
  static vars_interval = []
  static negative = []
  static durationList = {
    '1m': {
      id: '1',
      value: 30,
      minVal: 1,
      labelI18n: 'lk.subscribe.modal.daysLeftDetail',
    },
    '2m': {
      id: '2',
      value: 60,
      minVal: 31,
      labelI18n: 'lk.subscribe.modal.daysLeftDetail',
    },
    '3m': {
      id: '3',
      value: 90,
      minVal: 61,
      labelI18n: 'lk.subscribe.modal.daysLeftDetail',
    }
  }

  static plotOnChartDurationList = {
    '1m': {
      id: '1',
      value: 30,
      labelI18n: 'lk.subscribe.modal.daysLeftDetail',
    },
    'forever': {
      id: '2',
      value: 0,
      label: 'general.forever',
    },
  }

  static marketplace = {
    spot: {
      id: 'spot',
      label: 'Spot',
      labelI18n: 'lk.notifications.marketplace.types.spot',
      value: false,
    },
    futures: {
      id: 'spot',
      label: 'Spot',
      labelI18n: 'lk.notifications.marketplace.types.futures',
      value: true,
    }
  }

  static repeatableList = {
    once: {
      id: 'once',
      label: 'Once',
      labelI18n: 'lk.notifications.repeat.once',
    },
    every: {
      id: 'every',
      label: 'Every time',
      labelI18n: 'lk.notifications.repeat.every',
    }
  }
  static sendTypesList = {
    telegram: {
      label: '',
      i18nLabel: 'lk.notifications.target.tg'
    },
    browser_push: {
      label: '',
      i18nLabel: 'lk.notifications.target.web'
    },
    hook: {
      label: 'Webhook',
      i18nLabel: ''
    }
  }
  static isTg = (type) => {
    return type === 'telegram'
  }

  static isWeb = (type) => {
    return type === 'browser_push'
  }

  static isHook = (type) => {
    return type === 'hook'
  }
}
