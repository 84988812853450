<template>
  <div class="select">
    <div class="select-input-wrap" @click="toggleSelect">
      <input
        :readonly="!searchable"
        type="text"
        class="select-input form-control"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="selectedValue"
        ref="input-element"
      >
    </div>
    <ul v-show="selectOpened" class="select-options" ref="select-options">
      <li v-for="(option, key) in options" :key="`select_item_${key}`">
        <div class="group">
          <div class="group__title" @click.prevent="toggleOpenGroup(option.key)">{{ option.label }}</div>
          <transition
            @enter="startTransition"
            @after-enter="endTransition"
            @before-leave="startTransition"
            @after-leave="endTransition"
            name="v-collapse"
          >
            <div class="group-items v-collapse" v-show="isGroupOpened(option.key)">
              <div
                class="group__item"
                v-for="(item, index) in option.items"
                :key="`group_item_${index}`"
                @click="handleClickItem(item)"
              >
                {{ item.value }}
              </div>
            </div>
          </transition>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  name: 'my-multiselect',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    value: {
      type: null,
      default: () => {
      }
    },
    labelFunctionName: {
      type: null,
    },
    searchable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectOpened: false,
      openedGroups: [],
      selectedItem: '',
      oldValue: null
    }
  },
  computed: {
    selectedValue() {
      if (this.value) {
        return this.labelFunctionName(this.value)
      } else {
        return ''
      }
    }
  },
  watch: {
    // value(val, oldVal) {
    //   this.oldValue = oldVal
    //   console.log('test444',oldVal);
    // },
    value: {
      immediate: true,
      handler(val, oldVal) {
        this.oldValue = val
      }
    },
  },
  beforeDestroy() {
    this.closeSelect()
  },
  methods: {
    openSelect() {
      if (this.disabled) return
      this.selectOpened = true
    },
    handleClickOutside(e) {
      const isInsideClick = e.composedPath().some(element => element === this.$refs["select-options"])
      const inputElement = e.target === this.$refs["input-element"]
      if (!isInsideClick && !inputElement) {
        this.closeSelect()
      }
    },
    closeSelect() {
      this.selectOpened = false
      window.removeEventListener('click', this.handleClickOutside)
    },
    toggleSelect() {
      if (this.disabled) return
      this.selectOpened = !this.selectOpened
      if (this.selectOpened) {
        window.addEventListener('click', this.handleClickOutside)
      }
    },
    isGroupOpened(key) {
      return this.openedGroups.some(item => item === key)
    },
    toggleOpenGroup(key) {
      const index = this.openedGroups.findIndex(item => item === key)
      if (index > -1) {
        this.openedGroups.splice(index, 1)
      } else {
        this.openedGroups.push(key)
      }
    },
    handleClickItem(item) {
      this.closeSelect()
      this.$emit('input', item.key, this.oldValue)
    },
    startTransition(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    endTransition(el) {
      el.style.height = ''
    },
  }
}
</script>

<style lang="scss" scoped>
.select {
  position: relative;

  &-options {
    min-width: calc(100% - 20px);
    max-height: 350px;
    overflow-y: auto;
    position: absolute;
    z-index: 1000;
    background: var(--base-card-bg);
    padding: 0;
    margin: 0;
    width: fit-content;
    list-style-type: none;
    box-shadow: 0 3px 6px 0 rgb(0, 0, 0 / 15%);
    border: 1px solid rgba(60, 60, 60, 0.26);
    border-radius: 0 0 4px 4px;
  }

  &-input {
    color: var(--white);
  }
}

.group {
  &__title {
    padding-left: 20px;
    padding-right: 20px;
    background: #5897fb;
    cursor: pointer;
    color: var(--primary-contrast);
  }

  &__item {
    white-space: nowrap;
    padding: 2px 20px;
    cursor: pointer;

    &:hover {
      background: rgba(#20A8D8, 0.5);
      color: var(--primary-contrast);
    }
  }
}

.v-collapse {
  overflow: hidden;
}

.v-collapse-enter-active,
.v-collapse-leave-active {
  will-change: height;
  transition-property: height;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.v-collapse-enter,
.v-collapse-leave-to {
  height: 0 !important;
}
</style>
