<template>
  <CModal
    centered
    v-if="show"
    :show="show"
    @update:show="$emit('close')"
    class="hook-modal"
    :closeOnBackdrop="!currentHook.editMode"
  >
    <template #header>
      <div class="hook-modal__header">
        <h5 class="hook-modal__title">
          {{  currentHook?.hasOwnProperty('editIndex') ? currentHook?.name : $t('lk.notifications.createHook') }}
        </h5>
        <!-- <CSwitch
          color="primary"
          shape="pill"
          :disabled="!currentHook.editMode"
          :checked.sync="hookCopy.enable"
          class="switch-custom hook-modal__switch"
          id="switch"
        /> -->
        <CButton
          @click="copyText(JSON.stringify(isFutures ? finadyConfigPerp : finadyConfig))"
          color='secondary'
          class="hook-modal__copy-button"
        >
          {{$t('lk.notifications.copyFinandyConfig')}}
          <CIcon name="copy"/>
        </CButton>
        <CIcon
        class="hook-modal__header-close"
        name="cil-x"
        @click.native="closeHookModal" />
      </div>
    </template>
    <v-select-input
      class="cselect"
      v-model="selectedHook"
      :options="hooksList"
      :placeholder="$t('lk.notifications.hook')"
      label="name"
      @input="selectHook"
    >
      <template #no-options>
        {{ $t('general.emptySearch') }}
      </template>
    </v-select-input>
    <div class="mb-4 position-relative">
      <CInput
        v-model="hookCopy.name"
        :disabled="!currentHook.editMode"
        :label="$t('lk.training.name')"
      />
    </div>
    <div class="mb-4 position-relative">
      <CInput
        :disabled="!currentHook.editMode"
        :is-valid="showHookValidation ? isValidUrl : null"
        :invalid-feedback="$t('lk.notifications.enterValidUrl')"
        v-model="hookCopy.url"
        :label="$t('lk.notifications.signalUrl')"
        :placeholder="hookDomain"
      />
    </div>
    <div class="mb-4 position-relative">
      <label class="mb-2 mr-auto">
        {{ $t('lk.notifications.ryptovizorIp') }}:
        <span
          @click="copyText(hookIp)"
          class="hook-modal__ip-link"
        >
          {{ hookIp }}
          <CIcon
            class="ip-copy-icon"
            name="copy"
          />
        </span>
      </label>
    </div>
    <div>
      <label class="mb-2 mr-auto">
        {{ $t('lk.notifications.signalMessage') }}:
      </label>
      <CTextarea
        :disabled="!currentHook.editMode"
        :is-valid="showHookValidation ? isValidJson : null"
        :invalid-feedback="$t('lk.notifications.enterValidJson')"
        :placeholder="$t('lk.notifications.validJson')"
        rows="4"
        class="mb-0 w-100"
        v-model="hookCopy.json"
      />
    </div>
    <div class="hook-modal__btn-block">
      <CButton
        v-if="currentHook.editMode"
        class="w-50 d-flex align-items-center justify-content-center"
        color="primary"
        @click="saveHook"
      >
        <CIcon name="cil-save" class="my-0 mr-1" />
        {{$t('general.save')}}
      </CButton>
      <CButton
        class="w-50"
        color="dark"
        @click="closeHookModal">
        {{currentHook.editMode ? $t('general.cancel') : $t('general.close')}}
      </CButton>
    </div>
    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
import { copyTextToClipboard } from '@/assets/js/plugins/copyText';
import finadyConfig from '@/assets/js/finadyConfig';
import finadyConfigPerp from '@/assets/js/finadyConfigPerp';
import Hook from "@/assets/js/notifications/Hook.class";
import { validateUrl, validateJson } from "@/assets/js/helpers";
import VSelectInput from "@/components/VSelectInput";
import axios from "axios";

const HOOK_DOMAIN = 'https://hook.finandy.com/'
const HOO_IP = '195.201.131.240'

export default {
  name: "HookModal",
  components: {
    VSelectInput
  },
  props:{
    show: {
      type: Boolean,
      default: false
    },
    viewMode: {
      type: Boolean,
      default: false
    },
    currentHook:{
      type: Hook,
      default: () => ({})
    },
    isFutures: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return{
      finadyConfig: finadyConfig,
      finadyConfigPerp: finadyConfigPerp,
      hookIp: HOO_IP,
      hookDomain: HOOK_DOMAIN,
      hooksList: null,
      selectedHook: null,
      showHookValidation: false,
      hookCopy: {...this.currentHook},
    }
  },
  created () {
    this.getHooks()
  },
  destroyed () {
  },
  mounted(){
  },
  watch: {
    show(val) {
      if (val) {
        this.hookCopy = {...this.currentHook}
        this.getHooks()
      } else {
        this.hookCopy = null
        this.showHookValidation = false
        this.selectedHook = null
      }
    }
  },
  computed:{
    isValidUrl(){
      const val = this.hookCopy.url
      return validateUrl(val) && val.startsWith(this.hookDomain) && this.showHookValidation
    },
    isValidJson(){
      const val = this.hookCopy.json
      return validateJson(val) && this.showHookValidation
    },
  },
  methods:{
    copyText(text){
      copyTextToClipboard(text ,(status) => {
        if (status) {
          this.$toastr.success(this.$t('general.copied'));
        }
      })
    },
    selectHook(value){
      this.hookCopy.name = value.name
      this.hookCopy.url = value.url
      this.hookCopy.json = value.json
      this.hookCopy.enable = value.enable
    },
    getHooks() {
      this.hooksList = []
      axios.get('api/v2/hook_templates/all')
        .then(({ status, data }) => {
          if (status < 300) {
            this.hooksList = data.map((item,index) => new Hook({...item, id: index}));
          }
        })
    },
    closeHookModal(){
      this.$emit('close')
    },
    saveHook(){
      this.showHookValidation = true
      if(!this.isValidUrl || !this.isValidJson){
        return
      }
      console.log(this.hookCopy)
      this.$emit('saveHook', {
        ...this.hookCopy,
        enable: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.hook-modal{
  &__header{
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    &-close{
      position: absolute;
      cursor: pointer;
      user-select: none;
      right: 0;
      &:hover {
        color: $danger;
      }
    }
  }
  &__title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
  }
  &__switch{
    margin-left: 15px;
    margin-right: 30px;
    flex-shrink: 0;
  }
  &__btn-block{
    display: flex;
    column-gap: 10px;
    margin-top: 20px;
  }
  &__copy-button{
    margin-right: 24px;
    margin-left: 10px;
  }
  &__ip-link {
    display: inline-flex;
    align-items: center;
    color: var(--primary);
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.3s ease-out;
    &:hover {
      color: var(--white);
      .ip-copy-icon{
        opacity: 1;
      }
    }
  }
  .ip-copy-icon{
    opacity: 0;
    transition: opacity 0.3s ease-out;
    margin-left: 3px;
  }
}
.cselect {
  /deep/ .vs__selected-options {
    flex-wrap: nowrap;
    margin-right: 0.5rem;
  }
  /deep/ .vs__selected {
    white-space: nowrap;
  }
}
</style>
