<template>
  <CModal
    centered
    scrollable
    :closeOnBackdrop="!!viewMode"
    :title="
      notification.id
        ? notification.editMode
          ? $t('lk.notifications.editNotification')
          : $t('lk.notifications.viewNotification')
        : $t('lk.notifications.addBtn')
    "
    add-content-classes="ccontent-modal"
    :show="showModal"
    @update:show="closeEvent"
  >
    <template
      v-if="
        ((!telegramVerify || !isSubscribed) && !viewMode) ||
        (viewMode && (isTgNotifyDisabled || isWebNotifyDisabled))
      "
    >
      <button
        class="mb-3 category"
        @click="collapseNotifySettings = !collapseNotifySettings"
      >
        <span class="category-title">1</span>
        <span class="category-text">
          {{ $t("lk.notifications.notificationSettings") }}
        </span>
        <span class="dropdown-divider w-100" />
        <CIcon
          name="cil-chevron-circle-down-alt"
          class="cil-chevron"
          :class="{_show: !collapseNotifySettings}"
        />
      </button>

      <c-collapse
        :show="!collapseNotifySettings"
        :duration="200"
      >
        <web-push-toggle
          v-if="
            ($store.getters['user/getPermissions']('view_notifications') &&
              !isSubscribed &&
              !viewMode) ||
            (viewMode && isWebNotifyDisabled)
          "
        />
        <confirm-tg
          color="primary"
          class="px-2 mt-3"
          :text="$t('lk.account.connectTelegram')"
          :isRequestPassed="telegramVerify"
          v-if="!viewMode || (viewMode && isTgNotifyDisabled)"
        />
      </c-collapse>

      <div class="my-3 category">
        <div class="category-title">2</div>
        <div class="category-text">
          {{
            viewMode
              ? $t('lk.notifications.notification')
              : $t('lk.notifications.creatingNotification')
          }}
        </div>
        <div class="dropdown-divider w-100" />
      </div>
    </template>

    <template v-if="isSubscribed || telegramVerify || viewMode">
      <div class="mb-4">
        <p class="mb-2">{{ $t("lk.notifications.market") }}:</p>
        <CButtonGroup class="d-flex">
          <CButton
            v-for="(item, i) in NotificationClass.marketplace"
            :key="i"
            :disabled="disableMarkets || loading || !!viewMode || !!editSignalId"
            color="dark"
            class="cbutton"
            :variant="notification.is_futures === item.value ? '' : 'outline'"
            @click="toggleIsFutures(item.value)"
          >
            {{ item.labelI18n ? $t(item.labelI18n) : item.label }}
          </CButton>
        </CButtonGroup>
      </div>

      <template v-if="viewMode">
        <div
          v-if="isTgNotifyDisabled"
          class="text-danger d-flex mb-2"
        >
          <CIcon
            name="cil-warning"
            class="mr-2 flex-shrink-0"
          />
          {{ $t('lk.notifications.errorMessages.tg') }}
        </div>
        <div
          v-if="isWebNotifyDisabled"
          class="text-danger d-flex mb-2"
        >
          <CIcon
            name="cil-warning"
            class="mr-2 flex-shrink-0"
          />
          {{ $t('lk.notifications.errorMessages.web') }}
        </div>
      </template>
      <div
        v-if="pairs"
        class="form-group mb-4"
      >
        <div class="labelWrapper">
          <span>
            {{
              this.initialSignal?.is_single
                ? `${$t('lk.notifications.coin')}:`
                : `${$t('lk.notifications.coin')}/ Watch list:`
            }}
          </span>
          <transition
            name="fade"
            appear
          >
            <CButton
              v-if="selectedPairs.length && !viewMode && !notification.editMode"
              class="ml-2"
              color="light"
              :disabled="!!viewMode"
              @click="handleDeselectAllButton"
            >
              {{ $t("general.cancelAll") }}
            </CButton>
          </transition>
        </div>
        <!-- <div
          v-if="hasWatchList"
          class="notificationModal__coinsList">
          <span
            v-for="item in selectedEntity.pairs"
            :key="item.id"
            class="notificationModal__coinsList-item">
            {{item.label}}
          </span>
        </div> -->
        <CustomVSelect
          ref="coinListInput"
          :closeOnSelect="false"
          v-model="selectedPairs"
          :filter="customFilter"
          :options="pairs"
          :disabled="notification.sync_watchlist || loading || !!viewMode"
          :getOptionKey="(option) => option?.id"
          :getOptionLabel="(option) => option?.label || option?.symbol"
          class="v-select-trends flex-grow-1 flex-shrink-1"
          multiple
          :placeholder="$t('lk.notifications.placeholder')"
          @input="coinInputHandler"
        >
          <!--          @close="dropdownIsOpen = false"-->
          <!--          @open="openDropDown"-->
          <!--          @search="search"-->
          <template v-slot:option="option">
            <div class="rounded-pill">
              <!--              :class="{ _disabled: disabledOptionKey && option[disabledOptionKey] }"-->
              <span class="badge">
                {{ option?.label || option?.symbol }}
              </span>
            </div>
          </template>

          <!-- <template #selected-option-container="{option, deselect, multiple, disabled}">
            <span class="vs__selected badge">
              {{ option?.label || option?.symbol }}
              <button
                v-if="showRemoveCoin"
                :disabled="disabled"
                @click.stop="deselect(option)"
                ref="deselectButtons"
                type="button"
                class="vs__deselect"
                :title="`${$t('general.deselect')} ${option?.label || option?.symbol}`"
                :aria-label="`${$t('general.deselect')} ${option?.label || option?.symbol}`"
              >
                <CIcon
                  class="vs__deselect__icon"
                  name="cil-x"
                />
              </button>
            </span>
          </template> -->

          <template
            #list-header
            v-if="showListHeader"
          >
            <div class="watch-lists">
              <div
                v-if="futuresList?.length"
                class="v-select-option-list rounded-pill bg-base-card-bg"
                @click="setFutures"
              >
                <span>{{isFutures ? 'Spot' : 'Futures'}} {{ `Coins:${futuresList.length}` }}</span>
              </div>
              <template v-for="(item, i) in watchLists">
                <div
                  v-if="item.name && item.pairs && item.pairs.length"
                  class="v-select-option-list rounded-pill _watchlist"
                  :key="i"
                  :class="item.color ? `bg-${item.color}` : ''"
                  :style="{background: item.customColor}"
                  @click="watchListClickHandler(item.pairs)"
                >
                  <span>
                    {{ `${item.name} Coins:${item.pairs.length}` }}
                  </span>
                </div>
              </template>
            </div>
          </template>
        </CustomVSelect>
        <transition
          name="fade"
          appear
        >
          <CInputCheckbox
            v-if="!isFutures && showSyncFutures"
            class="mt-2"
            :checked.sync="notification.sync_watchlist"
            custom
            :label="$t('lk.notifications.syncFutures')"
          />
        </transition>
      </div>
      <CDropdownDivider />
      <div class="mt-3">
        <CRow
          v-for="(condition, i) in notification.conditions"
          :key="i"
        >
          <CCol
            col="12"
            class="d-flex align-items-center mb-2"
          >
            <label class="mb-0 mr-auto">
              {{ $t('lk.notifications.typeOfNotification') }}:
            </label>
            <CButton
              v-if="notification.conditions.length > 1"
              :disabled="loading || !!viewMode"
              color="danger"
              size="sm"
              @click="removeCondition(i)"
            >
              <CIcon name="cil-x" />
            </CButton>
          </CCol>
          <CCol col="12">
            <v-select-input
              class="cselect"
              v-model="condition.tf"
              :searchable="false"
              :disabled="(!isGroup && !hasPair) || loading || !!viewMode"
              :options="finalTfList"
              :placeholder="$t('lk.notifications.timeframe')"
              @input="tfInputEvent(i)"
            >
              <template #no-options>
                {{ $t("general.emptySearch") }}
              </template>
            </v-select-input>
          </CCol>
          <CCol col="12">
            <multiselect
              v-if="false"
              class="cselect form-group"
              :placeholder="$t('lk.notifications.variable')"
              v-model="condition.param"
              :disabled="!condition.tf || loading || !!viewMode"
              group-values="items"
              group-label="label"
              track-by="key"
              label="value"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              :options="notification.paramCalculate(condition.tf)"
            >
              <template slot="noResult">
                {{ $t("general.emptySearch") }}
              </template>
            </multiselect>
            <my-multiselect
              class="form-group"
              :searchable="false"
              v-model="condition.param"
              :placeholder="`${$t('lk.notifications.variable')}`"
              :disabled="!condition.tf || loading || !!viewMode"
              :label-function-name="(param) => notification.conditionName(param)"
              :options="notification.paramCalculate(condition.tf)"
              @input="(newVal, oldVal) => conditionChangeHandler(newVal, oldVal, i)"
            />
          </CCol>
          <transition
            name="fade"
            appear
          >
            <CCol v-if="showUnits(i)">
              <v-select-input
                class="cselect"
                v-model="selectedUnits[i]"
                :disabled="(!isGroup && !hasPair) || loading || !!viewMode"
                :options="unitsList"
                :clearable="false"
                :placeholder="$t('lk.notifications.unit')"
                @input="unitChangeHandler(i)"
              >
                <template #no-options>
                  {{ $t("general.emptySearch") }}
                </template>
              </v-select-input>
            </CCol>
          </transition>

          <CCol v-if="showInputValue(i)">
            <CInput
              class="cinput"
              :placeholder="$t('lk.notifications.input')"
              type="number"
              :ref="`conditionValueInput_${i}`"
              :value="condition.value"
              @input="conditionValueInputHandler($event, i)"
              :step="getStep(i)"
              :disabled="!condition.tf || loading || !!viewMode"
            />
          </CCol>
          <transition
            name="fade"
            appear
          >
            <CCol v-if="showNumberSigns(i)">
              <v-select-input
                class="cselect"
                v-model="selectedNumbersSigns[i]"
                :disabled="
                  !+condition.value || (!isGroup && !hasPair) || loading || !!viewMode
                "
                :options="numberSignList"
                :clearable="false"
                :placeholder="$t('lk.notifications.unit')"
                @input="numberSignChangeHandler(i)"
              >
                <template #no-options>
                  {{ $t("general.emptySearch") }}
                </template>
              </v-select-input>
            </CCol>
          </transition>
        </CRow>
      </div>
      <CDropdownDivider />
      <div
        v-if="!viewMode"
        class="mb-2 pb-2"
      >
        <CButton
          :disabled="loading"
          class="shadow-none text-white px-0"
          @click="addCondition"
        >
          <CIcon
            name="cil-plus"
            class="cc-icon bg-dark align-bottom rounded-circle my-0 mr-1 p-1"
          />
          {{ $t("lk.notifications.addMore") }}
        </CButton>
      </div>
      <div v-if="isGroup">
        <div class="d-flex mb-3 mt-3 align-items-center">
          <transition
            name="fade"
            appear
          >
            <CInputCheckbox
              class="mr-3"
              :checked.sync="notification.tv_notify_enable"
              custom
              :label="$t('lk.notifications.plotOnTheChart')"
            />
          </transition>
          <transition
            name="fade"
            appear
          >
            <tv-icons-picker
              v-if="notification.tv_notify_enable || notification.is_public"
              :open.sync="openTvIconPicker"
              :color.sync="notification.tv_notify_color"
              :icon.sync="notification.tv_notify_icon"
              :size.sync="notification.tv_notify_size"
            >
              <button
                v-tooltip="{
                  content: openTvIconPicker
                    ? null
                    : $t('lk.tg_notifications.edit'),
                }"
                class="color-btn"
                @click="openTvIconPicker = true"
              >
                <span
                  class="tv-icon-tag"
                  :style="{
                    color: notification.tv_notify_color
                      ? notification.tv_notify_color
                      : '#ff0000',
                    'font-size': notification.tv_notify_size
                      ? notification.tv_notify_size + 'px'
                      : '19px',
                    width: notification.tv_notify_size
                      ? notification.tv_notify_size + 5 + 'px'
                      : '24px',
                    height: notification.tv_notify_size
                      ? notification.tv_notify_size + 5 + 'px'
                      : '24px',
                    'line-height': notification.tv_notify_size
                      ? notification.tv_notify_size + 5 + 'px'
                      : '24px',
                  }"
                  v-html="
                    '&\#' +
                    (notification.tv_notify_icon ? notification.tv_notify_icon : 0xf0a2) +
                    ';'
                  "
                />
              </button>
            </tv-icons-picker>
          </transition>
        </div>
        <transition
          name="fade"
          appear
        >
          <div class="mb-4">
            <p class="mb-2">{{ $t('lk.notifications.saveOnGraph') }}:</p>
            <CButtonGroup class="d-flex">
              <CButton
                v-for="(item, i) in NotificationClass.plotOnChartDurationList"
                :key="i"
                color="dark"
                class="cbutton"
                :variant="
                  notification.tv_notify_days.id === item?.id ? '' : 'outline'
                "
                @click="$set(notification, 'tv_notify_days', item)"
              >
                {{
                  item?.labelI18n
                    ? `${item.value} ${$tc(item?.labelI18n, item.value)}`
                    : $t(item.label)
                }}
              </CButton>
            </CButtonGroup>
          </div>
        </transition>
      </div>

      <div
        v-if="!isGroup"
        class="mb-4"
      >
        <p class="mb-2">{{ $t('lk.notifications.repeat.title') }}:</p>
        <CButtonGroup class="d-flex">
          <CButton
            v-for="(item, i) in NotificationClass.repeatableList"
            :key="i"
            :disabled="loading || !!viewMode"
            color="dark"
            class="cbutton"
            :variant="
              notification.repeatableObj.id === item.id ? '' : 'outline'
            "
            @click="$set(notification, 'repeatableObj', item)"
          >
            {{ item.labelI18n ? $t(item.labelI18n) : item.label }}
          </CButton>
        </CButtonGroup>
      </div>

      <div
        v-if="isGroup"
        class="mb-4"
      >
        <p class="mb-2">{{ $t('marketplace.activity') }}:</p>
        <CButtonGroup class="d-flex">
          <CButton
            v-for="(item, i) in NotificationClass.durationList"
            :key="i"
            :disabled="loading || !!viewMode"
            color="dark"
            class="cbutton"
            :variant="
              notification.subscribe_duration.id === item?.id ? '' : 'outline'
            "
            @click="$set(notification, 'subscribe_duration', item)"
          >
            {{ `${item.value} ${$tc(item?.labelI18n, item.value)}` }}
          </CButton>
        </CButtonGroup>
      </div>
      <div class="mb-4">
        <p class="mb-2">{{ $t("lk.notifications.target.title") }}:</p>
        <CButtonGroup class="d-flex">
          <CButton
            v-for="(type, i) in NotificationClass.sendTypes"
            v-tooltip="{
              content: getTooltipContent(type),
            }"
            :key="i"
            :disabled="loading || !!viewMode"
            color="dark"
            class="cbutton"
            :variant="getSendType(type) ? '' : 'outline'"
            :class="{disabled: getSendTypeDisabled(type)}"
            @click="setSendType(type)"
          >
            {{ getSendTypeLabel(type) }}
          </CButton>
        </CButtonGroup>
      </div>
      <transition
        name="fade"
        appear
      >
        <div
          v-if="
            ((viewMode && notification.hooks.length) || !viewMode) &&
            notification.sendTypes.includes('hook')
          "
        >
          <CDropdownDivider />
          <label class="mb-0 mr-auto">Webhook:</label>
          <div class="notificationModal__hooks-wrapper">
            <div
              v-for="(item, i) in notification.hooks"
              :key="i"
              class="notificationModal__hook-item"
            >
              <div class="notificationModal__hook-info mr-3">
                {{ item.name }}
              </div>
              <div class="notificationModal__hook-controls">
                <CSwitch
                  color="primary"
                  shape="pill"
                  :disabled="viewMode"
                  :checked.sync="item.enable"
                  class="switch-custom mr-3"
                  id="switch"
                />
                <CButton
                  v-if="!viewMode"
                  class="mr-2"
                  color="success"
                  @click="editHook(item, i)"
                >
                  <CIcon name="cil-pencil" />
                </CButton>
                <CButton
                  v-if="!viewMode"
                  color="danger"
                  @click="deleteHook(i)"
                >
                  <CIcon name="cil-trash" />
                </CButton>
                <CButton
                  v-if="viewMode"
                  color="primary"
                  @click="showHook(item, i)"
                >
                  <CIcon name="cil-info" />
                </CButton>
              </div>
            </div>
          </div>
          <div
            v-show="!viewMode && !notification.hooks.length"
            class="mb-2 pb-2"
          >
            <CButton
              :disabled="loading"
              class="shadow-none text-white px-0"
              @click="addHook"
            >
              <CIcon
                name="cil-plus"
                class="cc-icon bg-dark align-bottom rounded-circle my-0 mr-1 p-1"
              />
              {{ $t("general.add") }}
            </CButton>
          </div>
        </div>
      </transition>
      <template v-if="isGroup">
        <CInputCheckbox
          v-if="!notification.editMode"
          class="mb-4 mt-2"
          :checked.sync="notification.is_public"
          custom
          :label="$t('general.public')"
        />
        <div class="mb-4 position-relative">
          <CInput
            class="notificationModal__nameInput"
            :value="notification.name"
            ref="nameInput"
            @input="nameInputHandler"
            :label="$t('marketplace.notificationName')"
          />
          <span class="notificationModal__comment-input-counter">
            {{ nameLengthLimit }}/{{ notification.name.length }}
          </span>
        </div>
        <div class="mb-4 notificationModal__comment-input-wrapper">
          <CTextarea
            id="descTextarea"
            @keyup.stop.shift.enter="textAreaSubmit('description', 'descTextarea')"
            @keyup.stop.ctrl.enter="textAreaSubmit('description', 'descTextarea')"
            @focus="isTextareaFocused = true"
            @blur="isTextareaFocused = false"
            ref="descTextarea"
            :placeholder="$t('lk.videoEncrypt.EditModal.description')"
            rows="4"
            class="mb-0 w-100"
            :value="notification.description"
            @input="descriptionInputHandler"
          />
          <span class="notificationModal__comment-input-counter">
            {{ descLengthLimit }}/{{ notification.description.length }}
          </span>
        </div>
      </template>
      <div
        v-if="!viewMode"
        class="mb-4 notificationModal__comment-input-wrapper"
      >
        <CTextarea
          id="commentInput"
          @keyup.stop.shift.enter="textAreaSubmit('comment', 'commentInput')"
          @keyup.stop.ctrl.enter="textAreaSubmit('comment', 'commentInput')"
          @focus="isTextareaFocused = true"
          @blur="isTextareaFocused = false"
          :placeholder="$t('lk.notifications.comment')"
          :rows="commentLengthLimit === 200 ? 5 : 4"
          ref="commentInput"
          :disabled="loading"
          class="mb-0 w-100 notificationModal__comment-input"
          :value="notification.comment"
          @input="commentInputHandler"
        />
        <span class="notificationModal__comment-input-counter">
          {{ commentLengthLimit }}/{{ notification.comment.length }}
        </span>
      </div>
      <template v-if="viewMode && notification.comment">
        <p class="mb-2">{{ $t("lk.notifications.comment") }}:</p>
        <span>
          {{ notification.comment }}
        </span>
      </template>
    </template>

    <div v-else>
      {{ $t("lk.notifications.errorMessages.allDisabled") }}
    </div>
    <CModal
      centered
      :show="showWarningModal"
      @update:show="showWarningModal = false"
      :title="$t('lk.notifications.important')"
      class="warningModal"
    >
      <span v-html="$t('lk.notifications.warning')"></span>
      <div class="warningModal__btnBlock">
        <CButton
          class="shadow-none warningModal__btn"
          color="primary"
          @click="showWarningModal = false"
        >
          OK
        </CButton>
      </div>
      <template #footer-wrapper><span></span></template>
    </CModal>

    <hook-modal
      :currentHook="currentHook"
      :show="showCreateHookModal"
      :is-futures="isFutures"
      @saveHook="saveHook"
      @close="closeHookModal"
    />
    <template v-slot:footer>
      <div class="d-flex w-100 m-0 notificationModal__controls">
        <CLoadingButtonCustom
          v-if="!viewMode && (isSubscribed || telegramVerify)"
          class="cbtn flex-grow-1"
          ref="submitButton"
          color="success"
          :loading="loading"
          :disabled="loading || !isSameExchange"
          @click.native="saveEvent"
          v-tooltip="toolTipContent"
        >
          <CIcon
            name="cil-save"
            class="my-0 mr-1"
          />
          {{ $t('general.save') }}
        </CLoadingButtonCustom>
        <transition
          name="fade"
          appear
        >
          <div
            v-if="isGroup && isSameExchange && !notification.editMode"
            class="notificationModal__token"
          >
            <span class="notificationModal__token-count">1</span>
            <CIcon
              v-tooltip="{content: $t('lk.notifications.tokenTooltip')}"
              name="coin_3"
            />
          </div>
        </transition>
        <CButton
          v-if="showDraft"
          :disabled="loading"
          class="shadow-none"
          color="warning"
          @click="saveAsDraft"
          v-tooltip="{ content: $t('lk.notifications.saveAsDraft') }"
        >
          <CIcon name="cilDescription" />
        </CButton>
        <CButton
          :disabled="loading"
          class="cbtn flex-grow-1"
          color="dark"
          @click="closeEvent"
        >
          {{
            viewMode || (!viewMode && !isSubscribed && !telegramVerify)
              ? $t('general.close')
              : $t('general.cancel')
          }}
        </CButton>
        <CButton
          v-if="notification.is_single && viewMode"
          color="success"
          @click="editSignal"
        >
          <CIcon name="cil-pencil" />
        </CButton>
      </div>
    </template>
  </CModal>
</template>

<script>
import HookModal from '@/components/notifications/HookModal.vue'
import TvIconsPicker from '@/components/common/TvIconsPicker.vue'
import axios from 'axios'
import VSelectInput from '@/components/VSelectInput'
import Multiselect from 'vue-multiselect'
import Condition from '@/assets/js/notifications/Condition.class'
import Notification from '@/assets/js/notifications/Notification.class'
import validate from '@/assets/js/notifications/validateNotification'
import WebPushToggle from '@/components/notifications/WebPushToggle'
import {mapGetters, mapState, mapActions} from 'vuex'
import ConfirmTg from '@/components/account/ConfirmTg'
import MyMultiselect from '@/components/common/MyMultiselect'
import screenerSettings from '@/assets/js/screenerSettings'
import CustomVSelect from '@/components/CustomVSelect'
import {getAltSearchTickersValue} from '@/assets/js/searchDataTickers'
import {getDecimalCount, sortByAlphabet} from '@/assets/js/helpers'
import Hook from '@/assets/js/notifications/Hook.class'
import {cloneDeep} from 'lodash'
import {CTextarea} from '@coreui/vue-pro'

export default {
  name: 'NotificationModal',
  components: {
    CTextarea,
    CustomVSelect,
    MyMultiselect,
    VSelectInput,
    WebPushToggle,
    ConfirmTg,
    Multiselect,
    TvIconsPicker,
    HookModal,
  },
  props: {
    notification: {
      type: [Object, Notification],
      required: true,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    pairsData: {
      type: Array,
      default: () => [],
    },
    isFuturesForRerender: {
      type: Boolean,
      default: false,
    },
    disableMarkets: Boolean,
    focusComment: Boolean,
  },
  data() {
    return {
      showSyncFutures: false,
      initialSignal: null,
      currentHook: null,
      showCreateHookModal: false,
      descLengthLimit: 255,
      nameLengthLimit: 125,
      isTextareaFocused: false,
      openTvIconPicker: false,
      mocTf: [],
      rectiveHack: [],
      tokens: [],
      showWarningModal: false,
      selectedPairs: this.notification.coins_pairs || [],
      //coins: [],
      settings: screenerSettings,
      Condition,
      NotificationClass: Notification,
      pairs: null,
      loading: false,
      collapseNotifySettings: false,
      watchLists: [],
      // selectedEntity: null,
      setColor: {
        danger: '#e55353',
        success: '#4DBD74',
        'watch-list-primary': '#4A90E2',
        warning: '#e1a82d',
      },
      numberSignList: [
        {
          label: this.$t("lk.notifications.positive"),
          value: "+",
        },
        {
          label: this.$t("lk.notifications.negative"),
          value: "-",
        },
      ],
      unitsList: [
        {
          label: this.$t("lk.notifications.unitLabel1"),
          step: 100,
          minVal: "100",
          //0s count after value
          value: 1000,
        },
        {
          label: this.$t("lk.notifications.unitLabel2"),
          step: 1,
          minVal: "1",
          //0s count after value
          value: 1000000,
        },
        {
          label: this.$t("lk.notifications.unitLabel3"),
          step: 0.5,
          minVal: "0.5",
          //0s count after value
          value: 1000000000,
        },
      ],
      selectedUnits: [
        {
          label: this.$t("lk.notifications.unitLabel1"),
          step: 100,
          minVal: "100",
          //0s count after value
          value: 1000,
        },
      ],
      selectedNumbersSigns: [
        {
          label: this.$t("lk.notifications.positive"),
          value: "+",
        },
      ],
    }
  },
  computed: {
    showDraft() {
      if (this.notification.editMode) {
        return false
      }
      if (!this.viewMode && (this.isSubscribed || this.telegramVerify)) {
        return true
      }
      return false
    },
    editSignalId() {
      return this.notification.marketplace_signal_id || this.notification.id
    },
    showRemoveCoin() {
      if (
        (this.notification.editMode &&
          !this.initialSignal?.is_single &&
          this.notification.coins_pairs.length < 3) ||
        this.notification.sync_watchlist
      ) {
        return false
      }
      return true
    },
    viewMode() {
      return this.notification?.id && !this.notification?.editMode
    },
    finalTfList() {
      return this.tfComputed || this.mocTf
    },
    commentLengthLimit() {
      return 200
    },
    moreThanFutures() {
      return this.selectedPirs?.length > this.futuresList?.length
    },
    toolTipContent() {
      if (
        this.isGroup &&
        !this.$store.getters['user/getTariffPermissions']('group_notifications')
      ) {
        return {
          content: `${this.$t('marketplace.actveTariffTooltip', {tariff: 'PRO+ALERTS'})}`,
        }
      }
      return { content: false }
    },
    futuresList() {
      if(this.isFutures){
        return this.pairs.filter((el) => el.is_spot)
      }
      return this.pairs.filter((el) => el.is_futures)
    },
    isSameExchange() {
      if (this.selectedPairs?.length > 1)
        return this.selectedPairs.every((item) => item.exchange.toLowerCase() === 'binance')
      return true
    },
    showListHeader() {
      return (
        (this.watchLists?.length || this.futuresList?.length) && !this.initialSignal?.is_single
      )
    },
    ...mapState({
      isSubscribed: (state) => state.browserNotify.isSubscribed,
      google2faEnabled: (state) => state.user.userData.google2faEnabled,
    }),
    ...mapGetters({
      telegramVerify: 'user/telegramVerify',
      isGuest: 'user/isGuest',
    }),
    tfComputed() {
      return this.notification.tfComputed
    },
    hasPair() {
      return this.notification.pair?.id
    },
    isGroup() {
      return this.notification?.coins_pairs?.length > 1
    },
    isTgNotifyDisabled() {
      return !this.telegramVerify && this.getSendType("telegram")
    },
    isWebNotifyDisabled() {
      return !this.isSubscribed && this.getSendType("browser_push")
    },
    // mixedSelectList(){
    //   return this.watchLists.concat(this.pairs)
    // }
    currentUser() {
      return this.$store.state.user.userData
    },
    isFutures() {
      return this.notification.is_futures
    },
  },
  watch: {
    showModal: {
      immediate: true,
      handler(val) {
        if (this.focusComment && val) {
          this.$nextTick(() => {
            setTimeout(() => {
              const textareaWrapper = this.$refs?.commentInput?.$el
              if (!textareaWrapper) return
              const textarea = textareaWrapper.querySelector('textarea')
              if (textarea) textarea.focus()
            }, 1)
          })
        }

        if (val) {
          this.$nextTick(() => {
            this.$refs.submitButton &&
              this.$refs.submitButton.$el &&
              this.$refs.submitButton.$el.focus()
          })
          window.addEventListener('keydown', this.keyDown)
          if (!this.viewMode || !this.notification.pair) {
            let initSendTypes = []
            try {
              const lsSubscribeSendTypes = JSON.parse(
                localStorage.getItem('subscribeSendTypes'),
              )
              if (lsSubscribeSendTypes && Array.isArray(lsSubscribeSendTypes)) {
                lsSubscribeSendTypes.forEach((item) => {
                  if (!this.getSendTypeDisabled(item)) {
                    initSendTypes.push(item)
                  }
                })
              }
            } catch (e) {}
            if (!initSendTypes.length) {
              if (!this.getSendTypeDisabled('telegram')) {
                initSendTypes.push('telegram')
              }
              if (!this.getSendTypeDisabled('browser_push')) {
                initSendTypes.push('browser_push')
              }
              if (!this.getSendTypeDisabled('hook')) {
                initSendTypes.push('hook')
              }
            }
            this.notification.sendTypes = initSendTypes
          }
          if (this.viewMode || this.notification.pair || this.notification.coins_pairs) {
            this.selectedPairs = this.notification.coins_pairs || [{...this.notification.pair}]
            // if(!this.beforeUpdateCalled){
            this.notification.conditions.forEach((item, index) => {
              if (item.hasUnit) {
                const numberParams = this.getNumberParams(item.value)
                if (numberParams.magnitude > 0) {
                  /* this.selectedUnits.push(this.unitsList[numberParams.magnitude - 1]) */

                  this.selectedUnits[index] = this.unitsList[numberParams.magnitude - 1]
                  this.notification.conditions[index].value = numberParams.scaled
                }
              }
              if (item.isNegative) {
                this.selectedNumbersSigns[index] = this.numberSignList.find(
                  (option) => option.value === item.sign,
                )
              }
            })
            // }
            // if(!this.notification?.id && (this.notification.pair || this.notification.coins_pairs)) this.beforeUpdateCalled = true;
          }
          if (this.notification.editMode) {
            this.initialSignal = cloneDeep(this.notification)
            if (this.notification.sync_watchlist) {
              this.showSyncFutures = true
            }
          }
        } else {
          // this.selectedEntity = null
          // this.notification.watchList = null
          // this.beforeUpdateCalled = false
          this.notification.coins_pairs = null
          this.notification.pair = null
          this.selectedPairs = []
          this.selectedUnits = [this.unitsList[0]]
          this.selectedNumbersSigns = [this.numberSignList[0]]
          window.removeEventListener('keydown', this.keyDown)
          this.initialSignal = null
          this.showSyncFutures = false
        }
      },
    },
    'notification.sync_watchlist'(value) {
      if (value) {
        this.selectedPairs = []
        this.setWatchList(this.futuresList)
      }
    },
    commentLengthLimit(val) {
      if (val < this.notification.comment.length) {
        this.notification.comment = this.notification.comment.slice(0, val)
      }
    },
    rectiveHack: {
      handler(newValue) {
        Notification.sets = newValue
        this.mocTf = this.notification.tfComputed
      },
      deep: true,
      immediate: true,
    },
    isSubscribed(val) {
      if (val) this.setInitSendTypes()
    },
    telegramVerify(val) {
      if (val) this.setInitSendTypes()
    },
    notification() {
      if (
        this.notification.pair &&
        !this.pairs.some((el) => this.notification?.pair?.id === el.id)
      ) {
        this.notification.pair = this.pairs.find(
          (el) =>
            el.symbol === this.notification.pair.symbol.split(':')[1] &&
            el.exchange === this.notification.pair.symbol.split(':')[0],
        )
      }
    },
    collapseNotifySettings(val) {
      if (val !== undefined) {
        this.$store.dispatch('setKey', {
          namespace: 'notifications',
          key: 'collapseNotifySettings',
          value: val,
        })
      }
    },
  },
  created() {
    if (this.isGuest) {
      return
    }
    this.getConfig()
    this.getTokens()
    this.collapseNotifySettings =
      this.$store.state.settings?.notifications?.collapseNotifySettings
  },
  mounted() {
    this.$root.$on('promoTariffChanged', () => {
      this.saveAsDraft()
      const notificationToSave = {...this.notification}
      notificationToSave.conditions = notificationToSave.conditions.map((item) =>
        item.toSave(),
      )
      localStorage.setItem('draftData', JSON.stringify(notificationToSave))
    })
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown)
  },
  methods: {
    setFutures() {
      this.showSyncFutures = true
      this.notification.sync_watchlist = true
    },
    editSignal() {
      this.initialSignal = cloneDeep(this.notification)
      this.notification.editMode = true
    },
    showHook(item, i) {
      this.currentHook = item
      this.currentHook.editIndex = i
      this.showCreateHookModal = true
    },
    openHookModal() {
      window.removeEventListener('keydown', this.keyDown)
      this.showCreateHookModal = true
    },
    editHook(item, i) {
      this.currentHook = new Hook({...item, editMode: true})
      this.currentHook.editIndex = i
      this.openHookModal()
    },
    deleteHook(index) {
      this.notification.hooks.splice(index, 1)
    },
    saveHook(item) {
      const editedHook = new Hook(item)
      if (!this.notification.hooks.length) {
        this.notification.hooks.push(editedHook)
      } else {
        const editIndex = this.currentHook.editIndex
        if (this.currentHook.hasOwnProperty('editIndex')) {
          delete this.currentHook.editIndex
          this.notification.hooks[editIndex] = editedHook
        } else {
          this.notification.hooks.push(editedHook)
        }
      }
      this.closeHookModal()
    },
    closeHookModal() {
      this.currentHook = null
      this.showCreateHookModal = false
      window.addEventListener("keydown", this.keyDown)
    },
    textAreaSubmit(value, id) {
      const textarea = document.querySelector(`#${id}`)
      const cursorPosition = textarea.selectionStart

      const text = textarea.value

      const newText = text.substring(0, cursorPosition) + '\n' + text.substring(cursorPosition)

      textarea.value = newText

      textarea.selectionStart = textarea.selectionEnd = cursorPosition + 1
      this.notification[value] = textarea.value
    },
    getPrecession(index) {
      const configPrecession =
        Notification.vars_interval?.[this.notification.conditions[index].param]?.precession
      let precession
      if (configPrecession) {
        precession = configPrecession
      } else {
        const paramNameArr = this.notification.conditions[index].param.split('_')
        if (paramNameArr[paramNameArr.length - 1] === 'pct') {
          precession = 1
        } else if (paramNameArr[0] === 'price') {
          let precessions = this.selectedPairs
            .map((item) => (item.hasOwnProperty('precession') ? item.precession : null))
            .filter((item) => item !== null)
          if (precessions.length) {
            precession = Math.max(...precessions)
          }
        } else {
          precession = 2
        }
      }
      return precession
    },
    conditionValueInputHandler(value, index) {
      if (value === '') {
        return
      }
      const decimalCount = getDecimalCount(value)
      if (Notification.negative.indexOf(this.notification.conditions[index].param) === -1) {
        if (value.includes('-')) {
          value = value.replace('-', '')
        }
      } else if (!value.includes('-')) {
        value = '-' + value
      }
      const precession = this.getPrecession(index)
      if (precession !== null && decimalCount > precession) {
        value = value.substring(0, value.indexOf('.') + precession + 1)
      }
      this.notification.conditions[index].value = this.$refs[
        `conditionValueInput_${index}`
      ][0].$data.state = value
    },
    commentInputHandler(val) {
      if (val.length > this.commentLengthLimit) {
        this.$refs.commentInput.state = this.notification.comment
      }
      if (val.length <= this.commentLengthLimit) this.notification.comment = val
    },
    descriptionInputHandler(val) {
      if (val.length > this.descLengthLimit) {
        this.$refs.descTextarea.state = this.notification.description
      }
      if (val.length <= this.descLengthLimit) this.notification.description = val
    },
    nameInputHandler(val) {
      if (val.length > this.nameLengthLimit) {
        this.$refs.nameInput.state = this.notification.name
      }
      if (val.length <= this.nameLengthLimit) this.notification.name = val
    },
    neededToken(tokenId = 3) {
      return this.tokens.find((item) => item?.token_id === tokenId)
    },
    getTokens() {
      const url = 'api/v2/marketplace-tokens/user'
      axios.get(url).then(({status, data}) => {
        if (status < 300) {
          this.tokens = data.data
        }
      })
    },
    getNumberParams(num) {
      const magnitude = Math.floor(Math.log10(num) / 3)
      const validMagnitude = magnitude > 3 ? 3 : magnitude
      const scaled = num / Math.pow(10, validMagnitude * 3)
      return {magnitude: validMagnitude, scaled}
    },
    addCondition() {
      this.notification.conditions.push(new Condition())
      this.selectedUnits.push(this.unitsList[0])
      this.selectedNumbersSigns.push(this.numberSignList[0])
    },
    addHook() {
      if (this.notification.hooks.length === 1) {
        return
      }
      this.currentHook = new Hook({ editMode: true, enable: true })
      this.openHookModal()
    },
    removeCondition(index) {
      this.notification.conditions.splice(index, 1)
      this.selectedUnits.splice(index, 1)
      this.selectedNumbersSigns.splice(index, 1)
    },
    numberSignChangeHandler(index) {
      this.notification.conditions[index].sign = this.selectedNumbersSigns[index].value
    },
    unitChangeHandler(index) {
      this.notification.conditions[index].value = this.selectedUnits[index].minVal
      if (this.notification.conditions[index].hasUnit) {
        this.notification.conditions[index].unit = this.selectedUnits[index].value
      }
    },
    showUnits(index) {
      if (this.notification.conditions[index].hasUnit) {
        this.notification.conditions[index].unit = this.selectedUnits[index]?.value
      }
      return (
        this.notification.conditions[index].hasUnit &&
        this.showInputValue(index)
      )
    },
    showNumberSigns(index) {
      return this.notification.conditions[index].hasNegative
    },
    showInputValue(index) {
      return this.notification.conditions[index].hasValue
    },
    conditionChangeHandler(newVal, oldVal, index) {
      //this.notification.conditions[index].value = undefined
      const oldParamConfig = Notification.vars_interval?.[oldVal]
      const paramConfig =
        Notification.vars_interval?.[this.notification.conditions[index].param]
      if (this.notification.conditions[index].hasNegative) {
        this.notification.conditions[index].sign = this.selectedNumbersSigns[index].value
      } else {
        this.notification.conditions[index].sign = '+'
        this.selectedNumbersSigns[index] = this.numberSignList[0]
      }
      if (!paramConfig?.has_pretty_value) {
        //get min value for param from config
        const value =
          this.notification.conditions[index].value &&
          oldParamConfig?.has_pretty_value === paramConfig?.has_pretty_value
            ? this.notification.conditions[index].value || 0
            : paramConfig?.min || 0
        //set min value for condition if it exists in config else 0
        this.notification.conditions[index].value = `${value}` || "0"
        return
      }
      if (paramConfig?.has_pretty_value) {
        this.notification.conditions[index].value =
          this.notification.conditions[index].value &&
          oldParamConfig?.has_pretty_value === paramConfig?.has_pretty_value
            ? this.notification.conditions[index].value || 0
            : this.selectedUnits[index].minVal || 0
        return
      }
      this.notification.conditions[index].value = "0"
    },
    getStep(index) {
      if (!this.notification.conditions[index].param) return 1
      const paramConfig =
        Notification.vars_interval?.[this.notification.conditions[index].param]
      if (!paramConfig?.has_pretty_value) {
        //get value input step for condition param from config,if it exists else 1
        if (paramConfig?.interval) return paramConfig.interval
        if (this.notification.conditions[index].value) {
          const precession = this.getPrecession(index)
          // const decimalsCount = getDecimalCount(this.notification.conditions[index].value)
          return +(1 / Math.pow(10, precession)).toFixed(precession)
        }
        return 1
      }
      //if has_pretty_value === true we get step from selectedUnits[index].step
      if (paramConfig?.has_pretty_value) {
        return this.selectedUnits[index]?.step
      }
    },
    handleDeselectAllButton() {
      if (this.viewMode) return
      this.showSyncFutures = false
      this.notification.sync_watchlist = false
      this.notification.coins_pairs = null
      this.notification.pair = null
      this.selectedPairs = []
    },
    coinInputHandler(val) {
      if (val.length && !this.viewMode) {
        if (val.length === 1) {
          this.notification.coins_pairs = null
          this.notification.pair = val[0]
        }
        if (val.length > 1) {
          if (this.notification.editMode && this.initialSignal?.is_single) {
            this.notification.coins_pairs = null
            this.notification.pair = val[1]
            this.selectedPairs = [val[1]]
          } else {
            this.notification.pair = null
            this.notification.coins_pairs = val
            if (!this.isSameExchange) {
              this.showWarningModal = true
            }
          }
        }
      }
      if (!val.length) {
        this.notification.pair = null
        this.notification.coins_pairs = null
      }
      // this.selectedUnits = [this.unitsList[0]]
      this.notification.conditions.forEach((item, index) => {
        if (item.tf && !this.finalTfList.includes(item.tf)) {
          this.removeCondition(index)
          return
        }
        if (item.param && item.tf) {
          const isParamExist = this.notification
            .paramCalculate(item.tf)
            .find((confItem) => confItem.items.some((param) => param.key === item.param))
          if (!isParamExist) {
            this.notification.conditions[index].param = null
            this.notification.conditions[index].value = 0
          }
        }
      })
      if (!this.notification.conditions.length) {
        this.notification.resetConditions()
      }
    },
    customFilter(options, search) {
      return options.filter((option) => {
        return (
          option.label.toLowerCase().includes(search.toLowerCase()) ||
          (option.labelAlt && option.labelAlt.toLowerCase().includes(search.toLowerCase()))
        )
      })
    },
    watchListClickHandler(watchlistPairs) {
      this.notification.sync_watchlist = false
      this.showSyncFutures = false
      this.setWatchList(watchlistPairs)
    },
    setWatchList(watchlistPairs) {
      this.selectedPairs = [...this.selectedPairs, ...watchlistPairs].filter(
        (obj, index, arr) => {
          return arr.findIndex((o) => o.id === obj.id) === index
        },
      )
      this.coinInputHandler(this.selectedPairs)
      // this.notification.coins_pairs = this.selectedPairs
    },
    getWatchLists() {
      this.watchLists = this.isFutures
        ? this.settings.watchListsBinancePerp
        : this.settings.watchLists
      // this.watchLists = this.$store.state.user.userData.createdWatchlists

      //   .map(item => {
      //     const instance = new WatchListsItem(item)
      //     instance.label = item.name
      //     instance.mainId = instance.id
      //     instance.id = instance.selectId
      //     instance.color = instance.color ? this.setColor[instance.color] : instance.customColor
      //     instance.pairs = sortByAlphabet(instance.pairs.map(item => ({...item, symbol: item.label, label: `${item.exchange}:${item.label}`, })), 'label')
      //     return instance
      //   })
      //   .filter(item => item.pairs && item.pairs.length)
    },
    getConfig() {
      axios
        .get('api/v1/signals/config', {
          params: {
            is_futures: this.isFutures ? 1 : null,
            locale: this.$root.$i18n.locale,
          },
        })
        .then(({status, data}) => {
          if (status < 300) {
            this.coins = data.data
            this.$store.commit("setPairs", this.coins)
            this.sortPairs(data?.data?.pairs || [])
            this.$emit("update:pairsData", this.pairs)
            this.$emit("onLoadPairsData")
            Notification.sets = data?.data?.sets || []
            this.rectiveHack = data?.data?.sets || []
            Notification.vars = data?.data?.vars || []
            Notification.vars_flat = data?.data?.vars_flat || []
            Notification.sendTypes = data?.data?.send_types || []
            Notification.vars_interval = data?.data?.vars_interval || []
            Notification.negative = data?.data?.negative || []
            this.setInitSendTypes()
            this.getWatchLists()
          }
        })
    },
    sortPairs(pairs) {
      this.pairs = pairs
      this.pairs.forEach((a) => {
        a.label = a.symbol.split(':').length === 2 ? a.symbol : a.exchange + ':' + a.symbol
        a.labelAlt = getAltSearchTickersValue(a.symbol)
      })
      this.pairs = sortByAlphabet(this.pairs, "label")
    },
    ...mapActions('promo', ['toggleShowPromoModal']),
    saveEvent() {
      if (this.isGroup && !this.currentUser.getAccess(2)) {
        this.toggleShowPromoModal({
          show: true,
          content: {
            id: 'pro_alerts',
            sliderData: [{src: 'promo1'}, {src: 'promo2'}, {src: 'promo3'}],
          },
        })
        return
      }

      if (this.moreThanFutures) {
        this.$toastr.warning(this.$t('marketplace.coinCountWarning', {type: 'CREATOR'}))
        return
      }
      if (
        this.isGroup &&
        !this.neededToken().active_token_count &&
        !this.neededToken(2).active_token_count &&
        !this.notification.editMode
      ) {
        this.$toastr.warning(
          this.$t('marketplace.tokenLimitWarning', {type: 'CREATOR/INFLUENCER'}),
        )
        return
      }

      if (!validate(this.notification)) {
        return
      }

      if (!this.telegramVerify && !this.isSubscribed) {
        return
      }
      localStorage.setItem(
        "subscribeSendTypes",
        JSON.stringify(this.notification.sendTypes),
      )

      this.loading = true

      const promise = this.notification.editMode
        ? this.notification.update(this.editSignalId)
        : this.notification.save()

      promise
        .then(({status, data}) => {
          if (status < 300) {
            if (data.error) {
              this.$toastr.warning(data.error)
            } else {
              this.$emit('getNotifications')
              this.$root.$emit('trendsNotificationCreated')
              this.$toastr.success(
                this.$t(
                  this.notification.editMode
                    ? 'lk.notifications.signalChanged'
                    : 'lk.notifications.addSuccess',
                ),
              )
            }
            this.closeEvent()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveAsDraft() {
      const validateResult = validate(this.notification, true)
      if (!validateResult) return

      this.loading = true
      this.notification
        .saveAsDraft()
        .then(({status, data}) => {
          if (status < 300) {
            if (data.error) {
              this.$toastr.warning(data.error)
            } else {
              this.$emit('getNotifications')
              // this.$root.$emit('trendsNotificationCreated')
              this.$toastr.success(this.$t("lk.notifications.savedInDraft"))
            }
            // this.closeEvent()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    closeEvent() {
      this.$emit('close')
    },
    // pairInputEvent () {
    //   this.notification.watchList = null
    //   this.notification.pair = null
    //   if(this.selectedEntity.isWatchList)this.notification.watchList = this.selectedEntity
    //   if(!this.selectedEntity.isWatchList)this.notification.pair = this.selectedEntity
    //   this.notification.resetConditions()
    // },
    tfInputEvent(conditionIndex) {
      this.notification.checkParam(conditionIndex)
    },
    getSendTypeLabel(type) {
      const obj = Notification.sendTypesList[type]

      if (obj?.i18nLabel) {
        return this.$t(obj.i18nLabel)
      } else return obj?.label || type
    },
    setSendType(type) {
      if (this.getSendTypeDisabled(type)) return
      const findIndex = this.notification.sendTypes.findIndex((el) => el === type)

      if (findIndex > -1) {
        if (this.notification.sendTypes.length > 1) {
          this.notification.sendTypes.splice(findIndex, 1)
        } else {
          this.notification.sendTypes.splice(findIndex, 1)

          this.$nextTick(() => {
            this.notification.sendTypes.push(type)
          })
        }
      } else {
        this.notification.sendTypes.push(type)
      }
    },
    getSendType(type) {
      const findIndex = this.notification.sendTypes.findIndex((el) => el === type)
      return findIndex > -1
    },
    getTooltipContent(type) {
      if (Notification.isTg(type)) {
        return !this.telegramVerify
          ? this.$t("lk.notifications.errorMessages.tg")
          : ""
      }
      if (Notification.isWeb(type)) {
        return !this.isSubscribed
          ? this.$t("lk.notifications.errorMessages.web")
          : ""
      }
      if (Notification.isHook(type)) {
        return !this.google2faEnabled
          ? this.$t("lk.notifications.errorMessages.hook")
          : ""
      }
      return ""
    },
    getSendTypeDisabled(type) {
      if (Notification.isTg(type)) {
        return !this.telegramVerify
      }
      if (Notification.isWeb(type)) {
        return !this.isSubscribed
      }
      if (Notification.isHook(type)) {
        return !this.google2faEnabled
      }
      return false
    },
    setInitSendTypes() {
      if (this.telegramVerify || this.isSubscribed) {
        return
        Notification.sendTypes.forEach((type) => {
          if (
            ((this.telegramVerify && Notification.isTg(type)) ||
              (this.isSubscribed && Notification.isWeb(type))) &&
            !this.notification.sendTypes.length
          ) {
            this.notification.sendTypes.push(type)
          }
        })
      }
    },
    keyDown(e) {
      if ((e.keyCode === 13 && e.shiftKey) || (e.keyCode === 13 && e.ctrlKey)) {
        e.preventDefault()
        return
      }
      if (e.keyCode === 13) {
        // if (e.keyCode === 13) {
        //   if(this.isTextareaFocused){
        //     return
        //   }
        this.saveEvent()
        e.preventDefault()
      }
      if (e.keyCode === 27) {
        this.closeEvent()
        window.removeEventListener("keydown", this.keyDown)
      }
    },
    toggleIsFutures(val) {
      if (!this.editSignalId) {
        this.$emit("update:isFuturesForRerender", val)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.labelWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  height: 33px;
}
// .ccontent-modal{
/deep/ .modal-footer {
  padding-left: 1rem !important;
  padding-right: calc(1rem + 8px) !important;
}
// }
.notificationModal {
  &__nameInput {
    /deep/ .form-control {
      padding-right: 4rem;
    }
  }

  &__comment-input-wrapper {
    position: relative;

    /deep/ textarea {
      padding-bottom: 1rem;
    }
  }
  &__comment-input {
  }
  &__comment-input-counter {
    position: absolute;
    bottom: 8px;
    right: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.3px;
    opacity: 0.5;
    display: block;
  }
  &__controls {
    gap: 10px;
  }
  &__coinsList {
    margin-top: 14px;
    min-height: 160px;
    max-height: 160px;
    overflow: auto;
    padding: 12px 42px 12px 12px;
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
    background: var(--second-card-bg);
    &-item {
      padding: 5px 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--primary);
      border-radius: 13px;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      height: fit-content;
      border: 1px solid var(--primary);
      &._inactive {
        background: inherit;
        color: var(--primary);
      }
    }
  }
  &__token {
    height: 100%;
    display: flex;
    align-items: center;
    // margin-right: 15px;
    & svg {
      width: 25px !important;
      height: 25px !important;
    }
  }
  &__token-count {
    font-size: 22px;
    margin-right: 5px;
  }
  &__hooks-wrapper {
  }
  &__hook-item {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--grid-border);
  }
  &__hook-info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__hook-controls {
    display: flex;
    align-items: center;
  }
}

.cc-icon {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.cinput {
  /deep/ * {
    color: var(--white);
  }
}
.cbutton {
  box-shadow: none !important;
  border: 1px solid var(--dark) !important;
  width: 50%;
  &:before {
    box-shadow: none !important;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }
}

.cselect {
  /deep/ .vs__selected-options {
    flex-wrap: nowrap;
    margin-right: 0.5rem;
  }
  /deep/ .vs__selected {
    white-space: nowrap;
  }
}

.category-title {
  background: var(--grid-border);
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
}

.category-text,
.category-title {
  font-size: 1.15em;
  margin-right: 0.5rem;
}

.category {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
  outline: none;
  white-space: nowrap;

  .cil-chevron {
    width: auto;
    height: 1.5rem;
    flex-shrink: 0;
    margin-left: 0.5rem;
    color: var(--dark);
    transition: all ease-in-out 0.2s;

    &._show {
      transform: rotate(-180deg);
    }
  }
}
.vs--disabled {
  /deep/ .vs__dropdown-toggle,
  /deep/ .vs__search,
  /deep/ .vs__open-indicator {
    background-color: transparent;
  }
}
.v-select-trends {
  /deep/ .vs__dropdown-toggle {
    padding: 10px 6px;
    border: 1px solid #5a6570;
    border-radius: 4px;
  }
  /deep/ .vs__actions {
    display: none;
  }
  /deep/ .vs__selected-options {
    overflow: auto;
    max-height: 120px;
  }

  /deep/ .vs__selected {
    padding: 3px 14px;
    background: var(--primary) !important;
    color: white;
    font-size: 14px;
    line-height: 17px;
    border-radius: 13px;
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    gap: 5px;

    .badge {
    }
  }

  /deep/ .vs__deselect {
    &__icon {
      color: white;
      transition: all ease-in-out 0.2s;
      &:hover {
        color: $danger;
      }
    }
  }

  /deep/ .vs__search {
    color: var(--white);
  }

  /deep/ .vs__dropdown-menu {
    display: flex;
    flex-wrap: wrap;
    background: var(--v-select-bg);
    gap: 8px;
    padding: 20px 13px;
  }

  /deep/ .vs__dropdown-option--selected {
    background: var(--primary) !important;
    border-color: var(--primary) !important;
  }

  /deep/ .vs__dropdown-option {
    padding: 3px 14px;
    background: transparent;
    border: 1px solid var(--white);
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
    border-radius: 13px;

    &--selected {
      .badge {
        color: white !important;
      }
    }

    .badge {
      font-size: 14px !important;
      line-height: 17px !important;
      color: var(--white);
      padding: 0;
    }
  }
  /deep/ .vs__deselect {
    position: relative;
    &__icon {
      color: white;
      &:hover {
        color: $danger;
      }
    }
  }
}
.select_options {
  display: flex;
  align-items: center;
}

.select_options .circle {
  margin: 0;
  margin-left: 3px;
  margin-bottom: 4px;
  line-height: 15px;
  font-size: 36px;
}
.watch-lists {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid var(--second-card-bg);
  border-bottom: 1px solid var(--second-card-bg);
}

.warningModal {
  &__btn {
    width: 25%;
    height: 44px;
    margin-left: auto;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
}
.form-group {
  /deep/ .group__item {
    white-space: initial;
  }
}

.color-btn {
  user-select: none;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  line-height: 0;
}
.v-popover {
  position: initial !important;
}
</style>
