var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select" }, [
    _c(
      "div",
      { staticClass: "select-input-wrap", on: { click: _vm.toggleSelect } },
      [
        _c("input", {
          ref: "input-element",
          staticClass: "select-input form-control",
          attrs: {
            readonly: !_vm.searchable,
            type: "text",
            disabled: _vm.disabled,
            placeholder: _vm.placeholder,
          },
          domProps: { value: _vm.selectedValue },
        }),
      ]
    ),
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.selectOpened,
            expression: "selectOpened",
          },
        ],
        ref: "select-options",
        staticClass: "select-options",
      },
      _vm._l(_vm.options, function (option, key) {
        return _c("li", { key: `select_item_${key}` }, [
          _c(
            "div",
            { staticClass: "group" },
            [
              _c(
                "div",
                {
                  staticClass: "group__title",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleOpenGroup(option.key)
                    },
                  },
                },
                [_vm._v(_vm._s(option.label))]
              ),
              _c(
                "transition",
                {
                  attrs: { name: "v-collapse" },
                  on: {
                    enter: _vm.startTransition,
                    "after-enter": _vm.endTransition,
                    "before-leave": _vm.startTransition,
                    "after-leave": _vm.endTransition,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isGroupOpened(option.key),
                          expression: "isGroupOpened(option.key)",
                        },
                      ],
                      staticClass: "group-items v-collapse",
                    },
                    _vm._l(option.items, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: `group_item_${index}`,
                          staticClass: "group__item",
                          on: {
                            click: function ($event) {
                              return _vm.handleClickItem(item)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.value) + " ")]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }