<template>
  <v-popover
    :handleResize="false"
    :open="open"
    :offset="15"
    trigger="manual"
    container=".c-app"
    placement="top-start"
    v-bind="popoverOptions"
    v-on="$listeners"
    @show="setColorPicker"
    @hide="hideEvent"
  >
    <slot/>

    <template slot="popover">
      <div
        ref="tv-icons-picker"
        class="tv-icons-picker"
        :class="{'_dark-theme': $store.getters.darkMode}"
        @mouseleave="$emit('update:open', false)"
      >
        <div ref="color-picker" class="tv-icons-picker__color"></div>
        <div :style="{ color : color ? color: '#ff0000'}" class="tv-icons-picker__icons">
          <i v-for="item in icons"
             :class="item === icon ? 'selected' : ''"
             v-html="'&#' + item + ';'"
             @click="setIcon(item)"
          />
        </div>
        <div :style="{ color : color ? color: '#ff0000'}" class="tv-icons-picker__sizes">
          <i v-for="item in sizes"
             :class="item === size ? 'selected' : ''"
             :style="{'font-size' : item + 'px', width: (item + 5) + 'px', height: (item + 5) + 'px', 'line-height': (item + 5) + 'px'}"
             v-html="'&#' + icon + ';'"
             :title="item"
             @click="setSize(item)"
          />
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
import colorPicker from 'tui-color-picker';
import randomColor from 'randomcolor';
export default {
  name: "TvIconsPicker",
  props: {
    icons: {
      type: Array,
      required: false,
      default: () => [
        0xf002,
        0xf006,
        0xf096,
        0xf00c,
        0xf05b,
        0xf05d,
        0xf06e,
        0xf08a,
        0xf0a1,
        0xf0a2,
        0xf0eb,
        0xf10c,
        0xf12a,
        //0xf132,
        0xf071,
        0xf175,
        0xf176
      ]
    },
    icon: {
      required: true,
      default: () => 0xf0a2
    },
    sizes: {
      type: Array,
      required: false,
      default: () => [19, 25, 31, 39, 45]
    },
    size: {
      required: true,
      default: () => 19
    },
    open: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    popoverOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      colorPickerInstance: null,
    }
  },
  beforeDestroy() {
    this.destroyColorPicker()
  },
  methods: {
    setColorPicker() {
      this.colorPickerInstance = colorPicker.create({
        container: this.$refs["color-picker"],
        usageStatistics: false,
        preset: randomColor({ count: 16 }),
        color: this.color,
      })

      this.colorPickerInstance.on('selectColor', (e) => {
        this.$emit('update:color', e.color)
        this.$emit('selectColor', e.color)
      });
    },
    destroyColorPicker() {
      if (this.colorPickerInstance) {
        try {
          this.colorPickerInstance.destroy()
        } catch (e) {}
      }
    },
    hideEvent() {
      this.$emit('update:open', false)
      setTimeout(() => {
        this.destroyColorPicker()
      }, 150)
    },
    setIcon(icon) {
      this.$emit('update:icon', icon)
    },
    setSize(size) {
      this.$emit('update:size', size)
    }
  },
}
</script>

<style scoped lang="scss">
.tv-icons-picker {
  border-radius: .25rem;
  padding: .5rem;
  background-color: transparent;
  max-width: 210px;

  /deep/ {
    .tui-colorpicker-container {
      margin: 0 auto;
    }
    input.tui-colorpicker-palette-toggle-slider {
      display: none;
    }

    .tui-colorpicker-slider-container {
      display: block !important;
    }

    .tui-colorpicker-palette-hex {
      width: 87%;
      margin: .25rem .25rem .25rem 0;
      padding: 0 .5rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      background-clip: padding-box;
      border: 1px solid #e4e7ea;
      color: #5c6873;
      background-color: #fff;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:focus {
        color: #5c6873;
        background-color: #fff;
        border-color: #8ad4ee;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.26);
      }
    }
  }

  &._dark-theme {
    /deep/ {
      .tui-colorpicker-palette-hex {
        color: rgba(255, 255, 255, 0.87);
        background-color: rgba(255, 255, 255, 0.05);
        border-color: rgba(255, 255, 255, 0.09);
      }

      .tui-colorpicker-huebar-handle {
        fill: white;
      }

      .tui-colorpicker-palette-button.tui-colorpicker-selected {
        border-color: white;
      }
    }
  }

  &__color {
    margin-bottom: 5px;
  }
  &__icons {
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
    align-content: center;
    align-items: center;
    i {
      font-family: TVFontAwesome;
      display: block;
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 19px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      margin-bottom: 2px;
      text-align: center;
      border: 1px solid transparent;

      &.selected{
        border-color: var(--white);
      }
    }
  }
  &__sizes {
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    border-top: 1px solid var(--secondary);

    i {
      font-family: TVFontAwesome;
      display: block;
      align-content: center;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 19px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border: 1px solid transparent;

      &.selected{
        border-color: var(--white);
      }
    }
  }
}

.v-popover {
   position: absolute;
 }

@font-face {
  font-family: TVFontAwesome;
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/40f9a03d174178efb12303caa9bc7cd8.woff2") format("woff2"), url("../../assets/fonts/3293616ec0c605c7c2db25829a0a509e.woff") format("woff");
}
</style>
