import Notification from "@/assets/js/notifications/Notification.class"

export default class Condition {
  constructor(init) {
    this.tf = init?.tf
    this.value = init?.value && Math.abs(init?.value)
    this.param = init?.var || init?.param
    this.unit = null
    this.sign = init?.value < 0 ? '-' : '+'
  }
  toSave() {
    const value = this.hasNoValue !== null ? this.hasNoValue : (this.hasUnit ? Number(this.value * this.unit) : Number(this.value))
    return {
      tf: this.tf,
      value: this.isNegative ? -value : value,
      var: this.param,
    }
  }

  get isNegative(){
    return this.sign === '-'
  }
  get hasNegative(){
    return Notification.vars_interval?.[this.param]?.has_negative || false
  }
  set hasUnit(value) {
    //todo заглушка
  }
  get hasUnit() {
    return Notification.vars_interval?.[this.param]?.has_pretty_value || false
  }
  get hasNoValue() {
    return Notification.vars_interval?.[this.param]?.has_no_value ? 1 : null
  }
  get hasValue() {
    return this.hasNoValue === null
  }
}
