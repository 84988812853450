var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-popover",
    _vm._g(
      _vm._b(
        {
          attrs: {
            handleResize: false,
            open: _vm.open,
            offset: 15,
            trigger: "manual",
            container: ".c-app",
            placement: "top-start",
          },
          on: { show: _vm.setColorPicker, hide: _vm.hideEvent },
        },
        "v-popover",
        _vm.popoverOptions,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm._t("default"),
      _c("template", { slot: "popover" }, [
        _c(
          "div",
          {
            ref: "tv-icons-picker",
            staticClass: "tv-icons-picker",
            class: { "_dark-theme": _vm.$store.getters.darkMode },
            on: {
              mouseleave: function ($event) {
                return _vm.$emit("update:open", false)
              },
            },
          },
          [
            _c("div", {
              ref: "color-picker",
              staticClass: "tv-icons-picker__color",
            }),
            _c(
              "div",
              {
                staticClass: "tv-icons-picker__icons",
                style: { color: _vm.color ? _vm.color : "#ff0000" },
              },
              _vm._l(_vm.icons, function (item) {
                return _c("i", {
                  class: item === _vm.icon ? "selected" : "",
                  domProps: { innerHTML: _vm._s("&#" + item + ";") },
                  on: {
                    click: function ($event) {
                      return _vm.setIcon(item)
                    },
                  },
                })
              }),
              0
            ),
            _c(
              "div",
              {
                staticClass: "tv-icons-picker__sizes",
                style: { color: _vm.color ? _vm.color : "#ff0000" },
              },
              _vm._l(_vm.sizes, function (item) {
                return _c("i", {
                  class: item === _vm.size ? "selected" : "",
                  style: {
                    "font-size": item + "px",
                    width: item + 5 + "px",
                    height: item + 5 + "px",
                    "line-height": item + 5 + "px",
                  },
                  attrs: { title: item },
                  domProps: { innerHTML: _vm._s("&#" + _vm.icon + ";") },
                  on: {
                    click: function ($event) {
                      return _vm.setSize(item)
                    },
                  },
                })
              }),
              0
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }