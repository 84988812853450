var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      attrs: {
        centered: "",
        scrollable: "",
        closeOnBackdrop: !!_vm.viewMode,
        title: _vm.notification.id
          ? _vm.notification.editMode
            ? _vm.$t("lk.notifications.editNotification")
            : _vm.$t("lk.notifications.viewNotification")
          : _vm.$t("lk.notifications.addBtn"),
        "add-content-classes": "ccontent-modal",
        show: _vm.showModal,
      },
      on: { "update:show": _vm.closeEvent },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "d-flex w-100 m-0 notificationModal__controls" },
                [
                  !_vm.viewMode && (_vm.isSubscribed || _vm.telegramVerify)
                    ? _c(
                        "CLoadingButtonCustom",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.toolTipContent,
                              expression: "toolTipContent",
                            },
                          ],
                          ref: "submitButton",
                          staticClass: "cbtn flex-grow-1",
                          attrs: {
                            color: "success",
                            loading: _vm.loading,
                            disabled: _vm.loading || !_vm.isSameExchange,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.saveEvent.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("CIcon", {
                            staticClass: "my-0 mr-1",
                            attrs: { name: "cil-save" },
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("general.save")) + " "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("transition", { attrs: { name: "fade", appear: "" } }, [
                    _vm.isGroup &&
                    _vm.isSameExchange &&
                    !_vm.notification.editMode
                      ? _c(
                          "div",
                          { staticClass: "notificationModal__token" },
                          [
                            _c(
                              "span",
                              { staticClass: "notificationModal__token-count" },
                              [_vm._v("1")]
                            ),
                            _c("CIcon", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.$t(
                                      "lk.notifications.tokenTooltip"
                                    ),
                                  },
                                  expression:
                                    "{content: $t('lk.notifications.tokenTooltip')}",
                                },
                              ],
                              attrs: { name: "coin_3" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm.showDraft
                    ? _c(
                        "CButton",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: _vm.$t("lk.notifications.saveAsDraft"),
                              },
                              expression:
                                "{ content: $t('lk.notifications.saveAsDraft') }",
                            },
                          ],
                          staticClass: "shadow-none",
                          attrs: { disabled: _vm.loading, color: "warning" },
                          on: { click: _vm.saveAsDraft },
                        },
                        [_c("CIcon", { attrs: { name: "cilDescription" } })],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "CButton",
                    {
                      staticClass: "cbtn flex-grow-1",
                      attrs: { disabled: _vm.loading, color: "dark" },
                      on: { click: _vm.closeEvent },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.viewMode ||
                              (!_vm.viewMode &&
                                !_vm.isSubscribed &&
                                !_vm.telegramVerify)
                              ? _vm.$t("general.close")
                              : _vm.$t("general.cancel")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm.notification.is_single && _vm.viewMode
                    ? _c(
                        "CButton",
                        {
                          attrs: { color: "success" },
                          on: { click: _vm.editSignal },
                        },
                        [_c("CIcon", { attrs: { name: "cil-pencil" } })],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      ((!_vm.telegramVerify || !_vm.isSubscribed) && !_vm.viewMode) ||
      (_vm.viewMode && (_vm.isTgNotifyDisabled || _vm.isWebNotifyDisabled))
        ? [
            _c(
              "button",
              {
                staticClass: "mb-3 category",
                on: {
                  click: function ($event) {
                    _vm.collapseNotifySettings = !_vm.collapseNotifySettings
                  },
                },
              },
              [
                _c("span", { staticClass: "category-title" }, [_vm._v("1")]),
                _c("span", { staticClass: "category-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("lk.notifications.notificationSettings")) +
                      " "
                  ),
                ]),
                _c("span", { staticClass: "dropdown-divider w-100" }),
                _c("CIcon", {
                  staticClass: "cil-chevron",
                  class: { _show: !_vm.collapseNotifySettings },
                  attrs: { name: "cil-chevron-circle-down-alt" },
                }),
              ],
              1
            ),
            _c(
              "c-collapse",
              { attrs: { show: !_vm.collapseNotifySettings, duration: 200 } },
              [
                (_vm.$store.getters["user/getPermissions"](
                  "view_notifications"
                ) &&
                  !_vm.isSubscribed &&
                  !_vm.viewMode) ||
                (_vm.viewMode && _vm.isWebNotifyDisabled)
                  ? _c("web-push-toggle")
                  : _vm._e(),
                !_vm.viewMode || (_vm.viewMode && _vm.isTgNotifyDisabled)
                  ? _c("confirm-tg", {
                      staticClass: "px-2 mt-3",
                      attrs: {
                        color: "primary",
                        text: _vm.$t("lk.account.connectTelegram"),
                        isRequestPassed: _vm.telegramVerify,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "my-3 category" }, [
              _c("div", { staticClass: "category-title" }, [_vm._v("2")]),
              _c("div", { staticClass: "category-text" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.viewMode
                        ? _vm.$t("lk.notifications.notification")
                        : _vm.$t("lk.notifications.creatingNotification")
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "dropdown-divider w-100" }),
            ]),
          ]
        : _vm._e(),
      _vm.isSubscribed || _vm.telegramVerify || _vm.viewMode
        ? [
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _c("p", { staticClass: "mb-2" }, [
                  _vm._v(_vm._s(_vm.$t("lk.notifications.market")) + ":"),
                ]),
                _c(
                  "CButtonGroup",
                  { staticClass: "d-flex" },
                  _vm._l(_vm.NotificationClass.marketplace, function (item, i) {
                    return _c(
                      "CButton",
                      {
                        key: i,
                        staticClass: "cbutton",
                        attrs: {
                          disabled:
                            _vm.disableMarkets ||
                            _vm.loading ||
                            !!_vm.viewMode ||
                            !!_vm.editSignalId,
                          color: "dark",
                          variant:
                            _vm.notification.is_futures === item.value
                              ? ""
                              : "outline",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleIsFutures(item.value)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.labelI18n
                                ? _vm.$t(item.labelI18n)
                                : item.label
                            ) +
                            " "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm.viewMode
              ? [
                  _vm.isTgNotifyDisabled
                    ? _c(
                        "div",
                        { staticClass: "text-danger d-flex mb-2" },
                        [
                          _c("CIcon", {
                            staticClass: "mr-2 flex-shrink-0",
                            attrs: { name: "cil-warning" },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("lk.notifications.errorMessages.tg")
                              ) +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isWebNotifyDisabled
                    ? _c(
                        "div",
                        { staticClass: "text-danger d-flex mb-2" },
                        [
                          _c("CIcon", {
                            staticClass: "mr-2 flex-shrink-0",
                            attrs: { name: "cil-warning" },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("lk.notifications.errorMessages.web")
                              ) +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.pairs
              ? _c(
                  "div",
                  { staticClass: "form-group mb-4" },
                  [
                    _c(
                      "div",
                      { staticClass: "labelWrapper" },
                      [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                this.initialSignal?.is_single
                                  ? `${_vm.$t("lk.notifications.coin")}:`
                                  : `${_vm.$t(
                                      "lk.notifications.coin"
                                    )}/ Watch list:`
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "transition",
                          { attrs: { name: "fade", appear: "" } },
                          [
                            _vm.selectedPairs.length &&
                            !_vm.viewMode &&
                            !_vm.notification.editMode
                              ? _c(
                                  "CButton",
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      color: "light",
                                      disabled: !!_vm.viewMode,
                                    },
                                    on: { click: _vm.handleDeselectAllButton },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("general.cancelAll")) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("CustomVSelect", {
                      ref: "coinListInput",
                      staticClass: "v-select-trends flex-grow-1 flex-shrink-1",
                      attrs: {
                        closeOnSelect: false,
                        filter: _vm.customFilter,
                        options: _vm.pairs,
                        disabled:
                          _vm.notification.sync_watchlist ||
                          _vm.loading ||
                          !!_vm.viewMode,
                        getOptionKey: (option) => option?.id,
                        getOptionLabel: (option) =>
                          option?.label || option?.symbol,
                        multiple: "",
                        placeholder: _vm.$t("lk.notifications.placeholder"),
                      },
                      on: { input: _vm.coinInputHandler },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "option",
                            fn: function (option) {
                              return [
                                _c("div", { staticClass: "rounded-pill" }, [
                                  _c("span", { staticClass: "badge" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          option?.label || option?.symbol
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "selected-option-container",
                            fn: function ({
                              option,
                              deselect,
                              multiple,
                              disabled,
                            }) {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "vs__selected badge" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          option?.label || option?.symbol
                                        ) +
                                        " "
                                    ),
                                    _vm.showRemoveCoin
                                      ? _c(
                                          "button",
                                          {
                                            ref: "deselectButtons",
                                            staticClass: "vs__deselect",
                                            attrs: {
                                              disabled: disabled,
                                              type: "button",
                                              title: `${_vm.$t(
                                                "general.deselect"
                                              )} ${
                                                option?.label || option?.symbol
                                              }`,
                                              "aria-label": `${_vm.$t(
                                                "general.deselect"
                                              )} ${
                                                option?.label || option?.symbol
                                              }`,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return deselect(option)
                                              },
                                            },
                                          },
                                          [
                                            _c("CIcon", {
                                              staticClass: "vs__deselect__icon",
                                              attrs: { name: "cil-x" },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            },
                          },
                          _vm.showListHeader
                            ? {
                                key: "list-header",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "watch-lists" },
                                      [
                                        _vm.futuresList?.length
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "v-select-option-list rounded-pill bg-base-card-bg",
                                                on: { click: _vm.setFutures },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Futures " +
                                                      _vm._s(
                                                        `Coins:${_vm.futuresList.length}`
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._l(
                                          _vm.watchLists,
                                          function (item, i) {
                                            return [
                                              item.name &&
                                              item.pairs &&
                                              item.pairs.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      key: i,
                                                      staticClass:
                                                        "v-select-option-list rounded-pill _watchlist",
                                                      class: item.color
                                                        ? `bg-${item.color}`
                                                        : "",
                                                      style: {
                                                        background:
                                                          item.customColor,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.watchListClickHandler(
                                                            item.pairs
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              `${item.name} Coins:${item.pairs.length}`
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                                proxy: true,
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.selectedPairs,
                        callback: function ($$v) {
                          _vm.selectedPairs = $$v
                        },
                        expression: "selectedPairs",
                      },
                    }),
                    _c(
                      "transition",
                      { attrs: { name: "fade", appear: "" } },
                      [
                        !_vm.isFutures && _vm.showSyncFutures
                          ? _c("CInputCheckbox", {
                              staticClass: "mt-2",
                              attrs: {
                                checked: _vm.notification.sync_watchlist,
                                custom: "",
                                label: _vm.$t("lk.notifications.syncFutures"),
                              },
                              on: {
                                "update:checked": function ($event) {
                                  return _vm.$set(
                                    _vm.notification,
                                    "sync_watchlist",
                                    $event
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("CDropdownDivider"),
            _c(
              "div",
              { staticClass: "mt-3" },
              _vm._l(_vm.notification.conditions, function (condition, i) {
                return _c(
                  "CRow",
                  { key: i },
                  [
                    _c(
                      "CCol",
                      {
                        staticClass: "d-flex align-items-center mb-2",
                        attrs: { col: "12" },
                      },
                      [
                        _c("label", { staticClass: "mb-0 mr-auto" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("lk.notifications.typeOfNotification")
                              ) +
                              ": "
                          ),
                        ]),
                        _vm.notification.conditions.length > 1
                          ? _c(
                              "CButton",
                              {
                                attrs: {
                                  disabled: _vm.loading || !!_vm.viewMode,
                                  color: "danger",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeCondition(i)
                                  },
                                },
                              },
                              [_c("CIcon", { attrs: { name: "cil-x" } })],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "CCol",
                      { attrs: { col: "12" } },
                      [
                        _c("v-select-input", {
                          staticClass: "cselect",
                          attrs: {
                            searchable: false,
                            disabled:
                              (!_vm.isGroup && !_vm.hasPair) ||
                              _vm.loading ||
                              !!_vm.viewMode,
                            options: _vm.finalTfList,
                            placeholder: _vm.$t("lk.notifications.timeframe"),
                          },
                          on: {
                            input: function ($event) {
                              return _vm.tfInputEvent(i)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "no-options",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("general.emptySearch")) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: condition.tf,
                            callback: function ($$v) {
                              _vm.$set(condition, "tf", $$v)
                            },
                            expression: "condition.tf",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "CCol",
                      { attrs: { col: "12" } },
                      [
                        false
                          ? _c(
                              "multiselect",
                              {
                                staticClass: "cselect form-group",
                                attrs: {
                                  placeholder: _vm.$t(
                                    "lk.notifications.variable"
                                  ),
                                  disabled:
                                    !condition.tf ||
                                    _vm.loading ||
                                    !!_vm.viewMode,
                                  "group-values": "items",
                                  "group-label": "label",
                                  "track-by": "key",
                                  label: "value",
                                  selectLabel: "",
                                  deselectLabel: "",
                                  selectedLabel: "",
                                  options: _vm.notification.paramCalculate(
                                    condition.tf
                                  ),
                                },
                                model: {
                                  value: condition.param,
                                  callback: function ($$v) {
                                    _vm.$set(condition, "param", $$v)
                                  },
                                  expression: "condition.param",
                                },
                              },
                              [
                                _c("template", { slot: "noResult" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("general.emptySearch")) +
                                      " "
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                        _c("my-multiselect", {
                          staticClass: "form-group",
                          attrs: {
                            searchable: false,
                            placeholder: `${_vm.$t(
                              "lk.notifications.variable"
                            )}`,
                            disabled:
                              !condition.tf || _vm.loading || !!_vm.viewMode,
                            "label-function-name": (param) =>
                              _vm.notification.conditionName(param),
                            options: _vm.notification.paramCalculate(
                              condition.tf
                            ),
                          },
                          on: {
                            input: (newVal, oldVal) =>
                              _vm.conditionChangeHandler(newVal, oldVal, i),
                          },
                          model: {
                            value: condition.param,
                            callback: function ($$v) {
                              _vm.$set(condition, "param", $$v)
                            },
                            expression: "condition.param",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "transition",
                      { attrs: { name: "fade", appear: "" } },
                      [
                        _vm.showUnits(i)
                          ? _c(
                              "CCol",
                              [
                                _c("v-select-input", {
                                  staticClass: "cselect",
                                  attrs: {
                                    disabled:
                                      (!_vm.isGroup && !_vm.hasPair) ||
                                      _vm.loading ||
                                      !!_vm.viewMode,
                                    options: _vm.unitsList,
                                    clearable: false,
                                    placeholder: _vm.$t(
                                      "lk.notifications.unit"
                                    ),
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.unitChangeHandler(i)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "no-options",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("general.emptySearch")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.selectedUnits[i],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.selectedUnits, i, $$v)
                                    },
                                    expression: "selectedUnits[i]",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.showInputValue(i)
                      ? _c(
                          "CCol",
                          [
                            _c("CInput", {
                              ref: `conditionValueInput_${i}`,
                              refInFor: true,
                              staticClass: "cinput",
                              attrs: {
                                placeholder: _vm.$t("lk.notifications.input"),
                                type: "number",
                                value: condition.value,
                                step: _vm.getStep(i),
                                disabled:
                                  !condition.tf ||
                                  _vm.loading ||
                                  !!_vm.viewMode,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.conditionValueInputHandler(
                                    $event,
                                    i
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "transition",
                      { attrs: { name: "fade", appear: "" } },
                      [
                        _vm.showNumberSigns(i)
                          ? _c(
                              "CCol",
                              [
                                _c("v-select-input", {
                                  staticClass: "cselect",
                                  attrs: {
                                    disabled:
                                      !+condition.value ||
                                      (!_vm.isGroup && !_vm.hasPair) ||
                                      _vm.loading ||
                                      !!_vm.viewMode,
                                    options: _vm.numberSignList,
                                    clearable: false,
                                    placeholder: _vm.$t(
                                      "lk.notifications.unit"
                                    ),
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.numberSignChangeHandler(i)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "no-options",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("general.emptySearch")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.selectedNumbersSigns[i],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.selectedNumbersSigns, i, $$v)
                                    },
                                    expression: "selectedNumbersSigns[i]",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
            _c("CDropdownDivider"),
            !_vm.viewMode
              ? _c(
                  "div",
                  { staticClass: "mb-2 pb-2" },
                  [
                    _c(
                      "CButton",
                      {
                        staticClass: "shadow-none text-white px-0",
                        attrs: { disabled: _vm.loading },
                        on: { click: _vm.addCondition },
                      },
                      [
                        _c("CIcon", {
                          staticClass:
                            "cc-icon bg-dark align-bottom rounded-circle my-0 mr-1 p-1",
                          attrs: { name: "cil-plus" },
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("lk.notifications.addMore")) + " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isGroup
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex mb-3 mt-3 align-items-center" },
                      [
                        _c(
                          "transition",
                          { attrs: { name: "fade", appear: "" } },
                          [
                            _c("CInputCheckbox", {
                              staticClass: "mr-3",
                              attrs: {
                                checked: _vm.notification.tv_notify_enable,
                                custom: "",
                                label: _vm.$t(
                                  "lk.notifications.plotOnTheChart"
                                ),
                              },
                              on: {
                                "update:checked": function ($event) {
                                  return _vm.$set(
                                    _vm.notification,
                                    "tv_notify_enable",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "transition",
                          { attrs: { name: "fade", appear: "" } },
                          [
                            _vm.notification.tv_notify_enable ||
                            _vm.notification.is_public
                              ? _c(
                                  "tv-icons-picker",
                                  {
                                    attrs: {
                                      open: _vm.openTvIconPicker,
                                      color: _vm.notification.tv_notify_color,
                                      icon: _vm.notification.tv_notify_icon,
                                      size: _vm.notification.tv_notify_size,
                                    },
                                    on: {
                                      "update:open": function ($event) {
                                        _vm.openTvIconPicker = $event
                                      },
                                      "update:color": function ($event) {
                                        return _vm.$set(
                                          _vm.notification,
                                          "tv_notify_color",
                                          $event
                                        )
                                      },
                                      "update:icon": function ($event) {
                                        return _vm.$set(
                                          _vm.notification,
                                          "tv_notify_icon",
                                          $event
                                        )
                                      },
                                      "update:size": function ($event) {
                                        return _vm.$set(
                                          _vm.notification,
                                          "tv_notify_size",
                                          $event
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: {
                                              content: _vm.openTvIconPicker
                                                ? null
                                                : _vm.$t(
                                                    "lk.tg_notifications.edit"
                                                  ),
                                            },
                                            expression:
                                              "{\n                content: openTvIconPicker\n                  ? null\n                  : $t('lk.tg_notifications.edit'),\n              }",
                                          },
                                        ],
                                        staticClass: "color-btn",
                                        on: {
                                          click: function ($event) {
                                            _vm.openTvIconPicker = true
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "tv-icon-tag",
                                          style: {
                                            color: _vm.notification
                                              .tv_notify_color
                                              ? _vm.notification.tv_notify_color
                                              : "#ff0000",
                                            "font-size": _vm.notification
                                              .tv_notify_size
                                              ? _vm.notification
                                                  .tv_notify_size + "px"
                                              : "19px",
                                            width: _vm.notification
                                              .tv_notify_size
                                              ? _vm.notification
                                                  .tv_notify_size +
                                                5 +
                                                "px"
                                              : "24px",
                                            height: _vm.notification
                                              .tv_notify_size
                                              ? _vm.notification
                                                  .tv_notify_size +
                                                5 +
                                                "px"
                                              : "24px",
                                            "line-height": _vm.notification
                                              .tv_notify_size
                                              ? _vm.notification
                                                  .tv_notify_size +
                                                5 +
                                                "px"
                                              : "24px",
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              "&#" +
                                                (_vm.notification.tv_notify_icon
                                                  ? _vm.notification
                                                      .tv_notify_icon
                                                  : 0xf0a2) +
                                                ";"
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("transition", { attrs: { name: "fade", appear: "" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v(
                              _vm._s(_vm.$t("lk.notifications.saveOnGraph")) +
                                ":"
                            ),
                          ]),
                          _c(
                            "CButtonGroup",
                            { staticClass: "d-flex" },
                            _vm._l(
                              _vm.NotificationClass.plotOnChartDurationList,
                              function (item, i) {
                                return _c(
                                  "CButton",
                                  {
                                    key: i,
                                    staticClass: "cbutton",
                                    attrs: {
                                      color: "dark",
                                      variant:
                                        _vm.notification.tv_notify_days.id ===
                                        item?.id
                                          ? ""
                                          : "outline",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$set(
                                          _vm.notification,
                                          "tv_notify_days",
                                          item
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item?.labelI18n
                                            ? `${item.value} ${_vm.$tc(
                                                item?.labelI18n,
                                                item.value
                                              )}`
                                            : _vm.$t(item.label)
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isGroup
              ? _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v(
                        _vm._s(_vm.$t("lk.notifications.repeat.title")) + ":"
                      ),
                    ]),
                    _c(
                      "CButtonGroup",
                      { staticClass: "d-flex" },
                      _vm._l(
                        _vm.NotificationClass.repeatableList,
                        function (item, i) {
                          return _c(
                            "CButton",
                            {
                              key: i,
                              staticClass: "cbutton",
                              attrs: {
                                disabled: _vm.loading || !!_vm.viewMode,
                                color: "dark",
                                variant:
                                  _vm.notification.repeatableObj.id === item.id
                                    ? ""
                                    : "outline",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$set(
                                    _vm.notification,
                                    "repeatableObj",
                                    item
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.labelI18n
                                      ? _vm.$t(item.labelI18n)
                                      : item.label
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isGroup
              ? _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v(_vm._s(_vm.$t("marketplace.activity")) + ":"),
                    ]),
                    _c(
                      "CButtonGroup",
                      { staticClass: "d-flex" },
                      _vm._l(
                        _vm.NotificationClass.durationList,
                        function (item, i) {
                          return _c(
                            "CButton",
                            {
                              key: i,
                              staticClass: "cbutton",
                              attrs: {
                                disabled: _vm.loading || !!_vm.viewMode,
                                color: "dark",
                                variant:
                                  _vm.notification.subscribe_duration.id ===
                                  item?.id
                                    ? ""
                                    : "outline",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$set(
                                    _vm.notification,
                                    "subscribe_duration",
                                    item
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${item.value} ${_vm.$tc(
                                      item?.labelI18n,
                                      item.value
                                    )}`
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _c("p", { staticClass: "mb-2" }, [
                  _vm._v(_vm._s(_vm.$t("lk.notifications.target.title")) + ":"),
                ]),
                _c(
                  "CButtonGroup",
                  { staticClass: "d-flex" },
                  _vm._l(_vm.NotificationClass.sendTypes, function (type, i) {
                    return _c(
                      "CButton",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: _vm.getTooltipContent(type),
                            },
                            expression:
                              "{\n            content: getTooltipContent(type),\n          }",
                          },
                        ],
                        key: i,
                        staticClass: "cbutton",
                        class: { disabled: _vm.getSendTypeDisabled(type) },
                        attrs: {
                          disabled: _vm.loading || !!_vm.viewMode,
                          color: "dark",
                          variant: _vm.getSendType(type) ? "" : "outline",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setSendType(type)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.getSendTypeLabel(type)) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c("transition", { attrs: { name: "fade", appear: "" } }, [
              ((_vm.viewMode && _vm.notification.hooks.length) ||
                !_vm.viewMode) &&
              _vm.notification.sendTypes.includes("hook")
                ? _c(
                    "div",
                    [
                      _c("CDropdownDivider"),
                      _c("label", { staticClass: "mb-0 mr-auto" }, [
                        _vm._v("Webhook:"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "notificationModal__hooks-wrapper" },
                        _vm._l(_vm.notification.hooks, function (item, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "notificationModal__hook-item",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "notificationModal__hook-info mr-3",
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "notificationModal__hook-controls",
                                },
                                [
                                  _c("CSwitch", {
                                    staticClass: "switch-custom mr-3",
                                    attrs: {
                                      color: "primary",
                                      shape: "pill",
                                      disabled: _vm.viewMode,
                                      checked: item.enable,
                                      id: "switch",
                                    },
                                    on: {
                                      "update:checked": function ($event) {
                                        return _vm.$set(item, "enable", $event)
                                      },
                                    },
                                  }),
                                  !_vm.viewMode
                                    ? _c(
                                        "CButton",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { color: "success" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editHook(item, i)
                                            },
                                          },
                                        },
                                        [
                                          _c("CIcon", {
                                            attrs: { name: "cil-pencil" },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.viewMode
                                    ? _c(
                                        "CButton",
                                        {
                                          attrs: { color: "danger" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteHook(i)
                                            },
                                          },
                                        },
                                        [
                                          _c("CIcon", {
                                            attrs: { name: "cil-trash" },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.viewMode
                                    ? _c(
                                        "CButton",
                                        {
                                          attrs: { color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showHook(item, i)
                                            },
                                          },
                                        },
                                        [
                                          _c("CIcon", {
                                            attrs: { name: "cil-info" },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.viewMode && !_vm.notification.hooks.length,
                              expression:
                                "!viewMode && !notification.hooks.length",
                            },
                          ],
                          staticClass: "mb-2 pb-2",
                        },
                        [
                          _c(
                            "CButton",
                            {
                              staticClass: "shadow-none text-white px-0",
                              attrs: { disabled: _vm.loading },
                              on: { click: _vm.addHook },
                            },
                            [
                              _c("CIcon", {
                                staticClass:
                                  "cc-icon bg-dark align-bottom rounded-circle my-0 mr-1 p-1",
                                attrs: { name: "cil-plus" },
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("general.add")) + " "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm.isGroup
              ? [
                  !_vm.notification.editMode
                    ? _c("CInputCheckbox", {
                        staticClass: "mb-4 mt-2",
                        attrs: {
                          checked: _vm.notification.is_public,
                          custom: "",
                          label: _vm.$t("general.public"),
                        },
                        on: {
                          "update:checked": function ($event) {
                            return _vm.$set(
                              _vm.notification,
                              "is_public",
                              $event
                            )
                          },
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "mb-4 position-relative" },
                    [
                      _c("CInput", {
                        ref: "nameInput",
                        staticClass: "notificationModal__nameInput",
                        attrs: {
                          value: _vm.notification.name,
                          label: _vm.$t("marketplace.notificationName"),
                        },
                        on: { input: _vm.nameInputHandler },
                      }),
                      _c(
                        "span",
                        {
                          staticClass:
                            "notificationModal__comment-input-counter",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.nameLengthLimit) +
                              "/" +
                              _vm._s(_vm.notification.name.length) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mb-4 notificationModal__comment-input-wrapper",
                    },
                    [
                      _c("CTextarea", {
                        ref: "descTextarea",
                        staticClass: "mb-0 w-100",
                        attrs: {
                          id: "descTextarea",
                          placeholder: _vm.$t(
                            "lk.videoEncrypt.EditModal.description"
                          ),
                          rows: "4",
                          value: _vm.notification.description,
                        },
                        on: {
                          keyup: [
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              $event.stopPropagation()
                              if (!$event.shiftKey) return null
                              return _vm.textAreaSubmit(
                                "description",
                                "descTextarea"
                              )
                            },
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              $event.stopPropagation()
                              if (!$event.ctrlKey) return null
                              return _vm.textAreaSubmit(
                                "description",
                                "descTextarea"
                              )
                            },
                          ],
                          focus: function ($event) {
                            _vm.isTextareaFocused = true
                          },
                          blur: function ($event) {
                            _vm.isTextareaFocused = false
                          },
                          input: _vm.descriptionInputHandler,
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass:
                            "notificationModal__comment-input-counter",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.descLengthLimit) +
                              "/" +
                              _vm._s(_vm.notification.description.length) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            !_vm.viewMode
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mb-4 notificationModal__comment-input-wrapper",
                  },
                  [
                    _c("CTextarea", {
                      ref: "commentInput",
                      staticClass:
                        "mb-0 w-100 notificationModal__comment-input",
                      attrs: {
                        id: "commentInput",
                        placeholder: _vm.$t("lk.notifications.comment"),
                        rows: _vm.commentLengthLimit === 200 ? 5 : 4,
                        disabled: _vm.loading,
                        value: _vm.notification.comment,
                      },
                      on: {
                        keyup: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.stopPropagation()
                            if (!$event.shiftKey) return null
                            return _vm.textAreaSubmit("comment", "commentInput")
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.stopPropagation()
                            if (!$event.ctrlKey) return null
                            return _vm.textAreaSubmit("comment", "commentInput")
                          },
                        ],
                        focus: function ($event) {
                          _vm.isTextareaFocused = true
                        },
                        blur: function ($event) {
                          _vm.isTextareaFocused = false
                        },
                        input: _vm.commentInputHandler,
                      },
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "notificationModal__comment-input-counter",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.commentLengthLimit) +
                            "/" +
                            _vm._s(_vm.notification.comment.length) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.viewMode && _vm.notification.comment
              ? [
                  _c("p", { staticClass: "mb-2" }, [
                    _vm._v(_vm._s(_vm.$t("lk.notifications.comment")) + ":"),
                  ]),
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.notification.comment) + " "),
                  ]),
                ]
              : _vm._e(),
          ]
        : _c("div", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("lk.notifications.errorMessages.allDisabled")) +
                " "
            ),
          ]),
      _c(
        "CModal",
        {
          staticClass: "warningModal",
          attrs: {
            centered: "",
            show: _vm.showWarningModal,
            title: _vm.$t("lk.notifications.important"),
          },
          on: {
            "update:show": function ($event) {
              _vm.showWarningModal = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer-wrapper",
              fn: function () {
                return [_c("span")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.$t("lk.notifications.warning")) },
          }),
          _c(
            "div",
            { staticClass: "warningModal__btnBlock" },
            [
              _c(
                "CButton",
                {
                  staticClass: "shadow-none warningModal__btn",
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showWarningModal = false
                    },
                  },
                },
                [_vm._v(" OK ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("hook-modal", {
        attrs: {
          currentHook: _vm.currentHook,
          show: _vm.showCreateHookModal,
          "is-futures": _vm.isFutures,
        },
        on: { saveHook: _vm.saveHook, close: _vm.closeHookModal },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }