var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "CModal",
        {
          staticClass: "hook-modal",
          attrs: {
            centered: "",
            show: _vm.show,
            closeOnBackdrop: !_vm.currentHook.editMode,
          },
          on: {
            "update:show": function ($event) {
              return _vm.$emit("close")
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "hook-modal__header" },
                      [
                        _c("h5", { staticClass: "hook-modal__title" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.currentHook?.hasOwnProperty("editIndex")
                                  ? _vm.currentHook?.name
                                  : _vm.$t("lk.notifications.createHook")
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "CButton",
                          {
                            staticClass: "hook-modal__copy-button",
                            attrs: { color: "secondary" },
                            on: {
                              click: function ($event) {
                                _vm.copyText(
                                  JSON.stringify(
                                    _vm.isFutures
                                      ? _vm.finadyConfigPerp
                                      : _vm.finadyConfig
                                  )
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lk.notifications.copyFinandyConfig")
                                ) +
                                " "
                            ),
                            _c("CIcon", { attrs: { name: "copy" } }),
                          ],
                          1
                        ),
                        _c("CIcon", {
                          staticClass: "hook-modal__header-close",
                          attrs: { name: "cil-x" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.closeHookModal.apply(null, arguments)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer-wrapper",
                fn: function () {
                  return [_c("span")]
                },
                proxy: true,
              },
            ],
            null,
            false,
            3021107831
          ),
        },
        [
          _c("v-select-input", {
            staticClass: "cselect",
            attrs: {
              options: _vm.hooksList,
              placeholder: _vm.$t("lk.notifications.hook"),
              label: "name",
            },
            on: { input: _vm.selectHook },
            scopedSlots: _vm._u(
              [
                {
                  key: "no-options",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t("general.emptySearch")) + " "),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3767542590
            ),
            model: {
              value: _vm.selectedHook,
              callback: function ($$v) {
                _vm.selectedHook = $$v
              },
              expression: "selectedHook",
            },
          }),
          _c(
            "div",
            { staticClass: "mb-4 position-relative" },
            [
              _c("CInput", {
                attrs: {
                  disabled: !_vm.currentHook.editMode,
                  label: _vm.$t("lk.training.name"),
                },
                model: {
                  value: _vm.hookCopy.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.hookCopy, "name", $$v)
                  },
                  expression: "hookCopy.name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-4 position-relative" },
            [
              _c("CInput", {
                attrs: {
                  disabled: !_vm.currentHook.editMode,
                  "is-valid": _vm.showHookValidation ? _vm.isValidUrl : null,
                  "invalid-feedback": _vm.$t("lk.notifications.enterValidUrl"),
                  label: _vm.$t("lk.notifications.signalUrl"),
                  placeholder: _vm.hookDomain,
                },
                model: {
                  value: _vm.hookCopy.url,
                  callback: function ($$v) {
                    _vm.$set(_vm.hookCopy, "url", $$v)
                  },
                  expression: "hookCopy.url",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "mb-4 position-relative" }, [
            _c("label", { staticClass: "mb-2 mr-auto" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("lk.notifications.ryptovizorIp")) + ": "
              ),
              _c(
                "span",
                {
                  staticClass: "hook-modal__ip-link",
                  on: {
                    click: function ($event) {
                      return _vm.copyText(_vm.hookIp)
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.hookIp) + " "),
                  _c("CIcon", {
                    staticClass: "ip-copy-icon",
                    attrs: { name: "copy" },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            [
              _c("label", { staticClass: "mb-2 mr-auto" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("lk.notifications.signalMessage")) + ": "
                ),
              ]),
              _c("CTextarea", {
                staticClass: "mb-0 w-100",
                attrs: {
                  disabled: !_vm.currentHook.editMode,
                  "is-valid": _vm.showHookValidation ? _vm.isValidJson : null,
                  "invalid-feedback": _vm.$t("lk.notifications.enterValidJson"),
                  placeholder: _vm.$t("lk.notifications.validJson"),
                  rows: "4",
                },
                model: {
                  value: _vm.hookCopy.json,
                  callback: function ($$v) {
                    _vm.$set(_vm.hookCopy, "json", $$v)
                  },
                  expression: "hookCopy.json",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "hook-modal__btn-block" },
            [
              _vm.currentHook.editMode
                ? _c(
                    "CButton",
                    {
                      staticClass:
                        "w-50 d-flex align-items-center justify-content-center",
                      attrs: { color: "primary" },
                      on: { click: _vm.saveHook },
                    },
                    [
                      _c("CIcon", {
                        staticClass: "my-0 mr-1",
                        attrs: { name: "cil-save" },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("general.save")) + " "),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "CButton",
                {
                  staticClass: "w-50",
                  attrs: { color: "dark" },
                  on: { click: _vm.closeHookModal },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.currentHook.editMode
                          ? _vm.$t("general.cancel")
                          : _vm.$t("general.close")
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }