export default class Pair {
  constructor(init = {}) {
    this.base = init?.base || ''
    this.exchange = init?.exchange || ''
    this.id = init?.id || null
    this.quote = init?.quote || ''
    this.symbol = init?.symbol || ''
    this.label = this.symbol.split(':').length === 2 || !this.exchange ? this.symbol : this.exchange + ':' + this.symbol
    this.precession = init?.precession || null
  }
}
