export default class Hook {
  constructor(init) {
    this.editMode = init?.editMode || false
    this.id = init?.id
    this.name = init?.name || ''
    this.enable = init?.enable || false
    this.active = init?.active
    this.url = init?.url || ''
    this.json = init?.json || ''
    this.meta = init?.meta
    this.validateErrors = init?.validateErrors
    this.updated_at = init.updated_at || null
  }
}
