import toastr from "toastr";
import validate from "@/assets/js/notifications/validateCondition";
import i18n from "@/i18n"

export default function (notification, isDraft) {
  if (!notification.pair?.id && !notification.coins_pairs) {
    toastr.warning(i18n.t('validateMessages.coinIsRequired'))
    return false
  }
  // for draft, not to pass ahead
  if(isDraft) return true
  if (!notification.sendTypes || !notification.sendTypes.length) {
    toastr.warning(i18n.t('validateMessages.sendingTypeIsRequired'))
    return false
  }
  if (!notification.repeatableObj?.id && !notification.subscribe_duration?.value) {
    toastr.warning(i18n.t('validateMessages.repeatabilityIsRequired'))
    return false
  }
  if (!notification.conditions) {
    toastr.warning(i18n.t('validateMessages.missingAlertType'))
    return false
  }
  return notification.conditions.every(v => validate(v))
}
