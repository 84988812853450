import toastr from "toastr";
import i18n from "@/i18n"

export default function (condition, showToaster = true) {
  if (!condition.tf) {
    if(showToaster) toastr.warning(i18n.t('validateMessages.timeframeIsRequired'))
    return false
  }
  if (!condition.param) {
    if(showToaster) toastr.warning(i18n.t('validateMessages.parameterIsRequired'))
    return false
  }
  if (/* typeof condition.value !== 'string' || !condition.value.length */ !condition.value) {
    if(showToaster) toastr.warning(i18n.t('validateMessages.valueIsRequired'))
    return false
  }
  return true
}
